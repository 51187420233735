<template>
    <ul class="price__features list">
         <li class="list__item">
            <svg v-if="tariff.title==='Base'" class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="12" height="10" fill="none">
                <path fill="currentColor" d="m11.1599 2.11979-5.51998 7.2c-.24.24-.6.48-.96.48s-.72-.12-.96-.48l-2.88-3.72c-.36-.48-.36-1.32.24-1.68.6-.36 1.32-.36 1.68.24l1.92 2.52 4.56-6c.36-.48 1.19998-.6 1.67998-.24.6.36.6 1.08.24 1.68Z"/>
            </svg>
            <svg v-else-if="tariff.title==='Expert'" class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="12" height="10" fill="none">
                <path fill="currentColor" d="m11.1599 2.11979-5.51998 7.2c-.24.24-.6.48-.96.48s-.72-.12-.96-.48l-2.88-3.72c-.36-.48-.36-1.32.24-1.68.6-.36 1.32-.36 1.68.24l1.92 2.52 4.56-6c.36-.48 1.19998-.6 1.67998-.24.6.36.6 1.08.24 1.68Z"/>
            </svg>
            <svg v-else-if="tariff.title==='Pro'" class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="12" height="10" fill="none">
                <path fill="currentColor" d="m11.1599 2.11979-5.51998 7.2c-.24.24-.6.48-.96.48s-.72-.12-.96-.48l-2.88-3.72c-.36-.48-.36-1.32.24-1.68.6-.36 1.32-.36 1.68.24l1.92 2.52 4.56-6c.36-.48 1.19998-.6 1.67998-.24.6.36.6 1.08.24 1.68Z"/>
            </svg>
            <svg v-else-if="tariff.title==='Top'" class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="12" height="10" fill="none">
                <path fill="currentColor" d="m11.1599 2.11979-5.51998 7.2c-.24.24-.6.48-.96.48s-.72-.12-.96-.48l-2.88-3.72c-.36-.48-.36-1.32.24-1.68.6-.36 1.32-.36 1.68.24l1.92 2.52 4.56-6c.36-.48 1.19998-.6 1.67998-.24.6.36.6 1.08.24 1.68Z"/>
            </svg>
            <svg v-else-if="tariff.title==='Individual'" xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" class="svg-icon">
                <path fill="currentColor" d="m11.1599 2.11979-5.51998 7.2c-.24.24-.6.48-.96.48s-.72-.12-.96-.48l-2.88-3.72c-.36-.48-.36-1.32.24-1.68.6-.36 1.32-.36 1.68.24l1.92 2.52 4.56-6c.36-.48 1.19998-.6 1.67998-.24.6.36.6 1.08.24 1.68Z"/>
            </svg>

<!--            <span v-if="tariff.title==='Individual'" style="color: #1B1B1B;font-weight: 700;font-size:16px;">Free chat, 24h</span>-->
<!--            <span v-else>1 Credit costs {{tariff.credit_cost}} cents</span>-->
             <span>1 Credit costs {{tariff.credit_cost}} cents</span>
        </li>

        <li class="list__item price__features-title">
            <svg v-if="tariff.title==='Base'" class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="12" height="10" fill="none">
                <path fill="currentColor" d="m11.1599 2.11979-5.51998 7.2c-.24.24-.6.48-.96.48s-.72-.12-.96-.48l-2.88-3.72c-.36-.48-.36-1.32.24-1.68.6-.36 1.32-.36 1.68.24l1.92 2.52 4.56-6c.36-.48 1.19998-.6 1.67998-.24.6.36.6 1.08.24 1.68Z"/>
            </svg>
            <svg v-else-if="tariff.title==='Expert'" class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="12" height="10" fill="none">
                <path fill="currentColor" d="m11.1599 2.11979-5.51998 7.2c-.24.24-.6.48-.96.48s-.72-.12-.96-.48l-2.88-3.72c-.36-.48-.36-1.32.24-1.68.6-.36 1.32-.36 1.68.24l1.92 2.52 4.56-6c.36-.48 1.19998-.6 1.67998-.24.6.36.6 1.08.24 1.68Z"/>
            </svg>
            <svg v-else-if="tariff.title==='Pro'" class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="12" height="10" fill="none">
                <path fill="currentColor" d="m11.1599 2.11979-5.51998 7.2c-.24.24-.6.48-.96.48s-.72-.12-.96-.48l-2.88-3.72c-.36-.48-.36-1.32.24-1.68.6-.36 1.32-.36 1.68.24l1.92 2.52 4.56-6c.36-.48 1.19998-.6 1.67998-.24.6.36.6 1.08.24 1.68Z"/>
            </svg>
            <svg v-else-if="tariff.title==='Top'" class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="12" height="10" fill="none">
                <path fill="currentColor" d="m11.1599 2.11979-5.51998 7.2c-.24.24-.6.48-.96.48s-.72-.12-.96-.48l-2.88-3.72c-.36-.48-.36-1.32.24-1.68.6-.36 1.32-.36 1.68.24l1.92 2.52 4.56-6c.36-.48 1.19998-.6 1.67998-.24.6.36.6 1.08.24 1.68Z"/>
            </svg>
            <svg v-else-if="tariff.title==='Individual'" xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" class="svg-icon">
                <path fill="currentColor" d="m11.1599 2.11979-5.51998 7.2c-.24.24-.6.48-.96.48s-.72-.12-.96-.48l-2.88-3.72c-.36-.48-.36-1.32.24-1.68.6-.36 1.32-.36 1.68.24l1.92 2.52 4.56-6c.36-.48 1.19998-.6 1.67998-.24.6.36.6 1.08.24 1.68Z"/>
            </svg>
            <span>{{bonuses}} Bonus credits</span>
        </li>

        <template v-if="tariff.addition">
            <li class="list__item" v-for="addition in tariff.addition">
                <svg v-if="tariff.title==='Base'" class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="12" height="10" fill="none">
                    <path fill="currentColor" d="m11.1599 2.11979-5.51998 7.2c-.24.24-.6.48-.96.48s-.72-.12-.96-.48l-2.88-3.72c-.36-.48-.36-1.32.24-1.68.6-.36 1.32-.36 1.68.24l1.92 2.52 4.56-6c.36-.48 1.19998-.6 1.67998-.24.6.36.6 1.08.24 1.68Z"/>
                </svg>
                <svg v-else-if="tariff.title==='Expert'" class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="12" height="10" fill="none">
                    <path fill="currentColor" d="m11.1599 2.11979-5.51998 7.2c-.24.24-.6.48-.96.48s-.72-.12-.96-.48l-2.88-3.72c-.36-.48-.36-1.32.24-1.68.6-.36 1.32-.36 1.68.24l1.92 2.52 4.56-6c.36-.48 1.19998-.6 1.67998-.24.6.36.6 1.08.24 1.68Z"/>
                </svg>
                <svg v-else-if="tariff.title==='Pro'" class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="12" height="10" fill="none">
                    <path fill="currentColor" d="m11.1599 2.11979-5.51998 7.2c-.24.24-.6.48-.96.48s-.72-.12-.96-.48l-2.88-3.72c-.36-.48-.36-1.32.24-1.68.6-.36 1.32-.36 1.68.24l1.92 2.52 4.56-6c.36-.48 1.19998-.6 1.67998-.24.6.36.6 1.08.24 1.68Z"/>
                </svg>
                <svg v-else-if="tariff.title==='Top'" class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="12" height="10" fill="none">
                    <path fill="currentColor" d="m11.1599 2.11979-5.51998 7.2c-.24.24-.6.48-.96.48s-.72-.12-.96-.48l-2.88-3.72c-.36-.48-.36-1.32.24-1.68.6-.36 1.32-.36 1.68.24l1.92 2.52 4.56-6c.36-.48 1.19998-.6 1.67998-.24.6.36.6 1.08.24 1.68Z"/>
                </svg>
                <svg v-else-if="tariff.title==='Individual'" xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" class="svg-icon">
                    <path fill="currentColor" d="m11.1599 2.11979-5.51998 7.2c-.24.24-.6.48-.96.48s-.72-.12-.96-.48l-2.88-3.72c-.36-.48-.36-1.32.24-1.68.6-.36 1.32-.36 1.68.24l1.92 2.52 4.56-6c.36-.48 1.19998-.6 1.67998-.24.6.36.6 1.08.24 1.68Z"/>
                </svg>
                <span>{{addition}}</span>
            </li>
        </template>

    </ul>
</template>

<script>
export default {
    name: "PriceFeaturesList",
    props:['tariff','bonuses']
}
</script>

<style scoped>

</style>
