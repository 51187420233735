<template>
    <div class="chat chat_support" :class="{'chat_mobile':$mq!=='desktop'}">
        <CoolLightBox
            :items="mediaChat"
            :index="indexMedia"
            :loop="false"
            :slideshow="false"
            :close-on-click-outside-mobile="true"
            @close="indexMedia = null">
        </CoolLightBox>
        <div class="chat__container">
            <div class="chat__wrapper">
                <div class="chat__header">
                    <div class="contact">
                        <svg class="chat__animated-icon" xmlns="http://www.w3.org/2000/svg" width="123" height="77"
                             fill="none">
                            <path class="wheel" id="wheel1" fill="#E9E9E9" fill-opacity=".15"
                                  d="m86.687 11.6661-7.0157 1.1373-.5612 6.6765c-2.6664 1.0572-5.0744 2.5619-7.1441 4.4173l-6.2209-2.4854-4.156 5.7657 4.3222 5.1171c-1.1033 2.5514-1.771 5.311-1.9301 8.1746l-6.1543 2.6441 1.14 7.0148 6.6767.5611c1.0545 2.6668 2.562 5.0741 4.4147 7.1442l-2.4824 6.2229 5.7659 4.1558 5.1169-4.3249c2.5515 1.1033 5.3116 1.7736 8.1749 1.9299l2.6441 6.154 7.0156-1.1373.5618-6.6737c2.6669-1.0544 5.0739-2.5619 7.1439-4.4173l6.221 2.4854 4.156-5.7657-4.325-5.1166c1.103-2.5514 1.771-5.311 1.93-8.1746l6.155-2.6441-1.138-7.0153-6.674-.5616c-1.054-2.6668-2.562-5.0741-4.417-7.1437l2.485-6.2206-5.765-4.1558-5.1173 4.3249c-2.5515-1.1033-5.3111-1.7709-8.1749-1.9299l-2.6441-6.154-.0037-.0051Zm17.058 27.594c1.412 8.7052-4.4998 16.9094-13.2082 18.3224-8.7056 1.4125-16.91-4.4992-18.3229-13.2072-1.4129-8.7079 4.4996-16.9093 13.208-18.3223 8.7084-1.413 16.9101 4.4992 18.3231 13.2071Z"/>
                            <path class="wheel" id="wheel2" fill="#E9E9E9" fill-opacity=".15"
                                  d="m18.6231 22.2415 5.0321 1.6416c.3177 2.173 1.0165 4.3055 2.098 6.2957l-3.0384 4.335 3.6778 4.2416 4.7202-2.3966c1.8171 1.3535 3.8295 2.3442 5.9372 2.9669l.9147 5.2116 5.5998.3963 1.6418-5.032c2.1731-.3178 4.3058-1.0166 6.2961-2.0981l4.3325 3.039 4.2419-3.6778-2.3965-4.7199c1.3536-1.817 2.3444-3.8294 2.9673-5.937l5.2118-.9148.3966-5.5996-5.0322-1.6415c-.3177-2.173-1.0165-4.3055-2.098-6.2958l3.0394-4.33233-3.6778-4.2416-4.7202 2.39656c-1.8171-1.35347-3.8305-2.3468-5.9372-2.96689l-.9147-5.21158-5.6007-.39893-1.6419 5.03199c-2.1731.31775-4.3067 1.01396-6.2961 2.09815l-4.3324-3.03908-4.2419 3.67782 2.3964 4.71987c-1.3536 1.81702-2.3444 3.82942-2.9682 5.93442l-5.2118.9148-.3966 5.5996.001.0026Zm15.5505-10.6568c5.2644-4.56485 13.2345-4.00156 17.8003 1.265 4.5684 5.2657 4.0012 13.2338-1.2632 17.7986-5.2643 4.5649-13.2345 4.0016-17.8003-1.265-4.5648-5.264-4.0012-13.2338 1.2658-17.7996l-.0026.001Z"/>
                            <path class="wheel" id="wheel3" fill="#E9E9E9" fill-opacity=".15"
                                  d="m33.8101 48.2205-.5035-3.8678-3.7277-.4294c-.5403-1.4823-1.3379-2.8288-2.3363-4.0008l1.5076-3.3663-3.148-2.3855-2.9471 2.2774c-1.4086-.6538-2.9415-1.0694-4.5395-1.2107l-1.3633-3.4245-3.9484.4931-.4412 3.649c-1.5132.5291-2.89056 1.3105-4.08413 2.2913l-3.43644-1.4768-2.4352 3.0837 2.3249 2.887c-.66749 1.3798-1.09175 2.8815-1.23599 4.4496L0 48.5253l.50628 3.8678 3.72493.4322c.54021 1.4823 1.33781 2.8316 2.33904 4.0008l-1.50751 3.3663 3.14795 2.3855 2.94711-2.2775c1.4085.6539 2.9415 1.0695 4.5395 1.2108l1.3633 3.4245 3.9484-.4959.4412-3.6517c1.5132-.5292 2.8877-1.3105 4.0841-2.2886l3.4365 1.4768 2.4352-3.0837-2.3249-2.887c.6646-1.377 1.0917-2.8815 1.236-4.4469l3.4958-1.3354-.0028-.0028Zm-15.7709 8.8466c-4.9015.6151-9.38446-2.7817-10.00952-7.5832-.62507-4.8015 2.83962-9.1929 7.74122-9.8052 4.9015-.6123 9.3816 2.7817 10.0095 7.5832.6279 4.8015-2.8397 9.1901-7.7412 9.8052Z"/>
                        </svg>
                        <div class="contact__avatar"></div>
                        <div class="contact__info">
                            <span class="contact__title">
                                <span class="contact__name">Chat Assistant</span>
                            </span>
                            <span class="status status_online" v-if="$mq==='desktop'">
                                <i class="status__icon"></i>
                                 <span>Live</span>
                            </span>
                            <span class="status status_online" v-else>
                                <i class="status__icon"></i>
                                 <span>Online</span>
                            </span>
                        </div>
                    </div>
                    <div class="chat__actions">
                        <button class="button button_icon chat__close" aria-label="Close" @click="closeSupport">
                            <span></span>
                        </button>
                    </div>
                </div>
                <div class="chat__body" ref="supportChatList">
                    <ul class="chat__list">
                        <template v-for="(messageArr, date) in supportMessages" >
                            <li class="text-center mb-2">
                                <span class="chat__date">{{writeDate(date)}}</span>
                            </li>
                            <li class="chat__item" :class="{'is-start':message.admin_id,'is-end':!message.admin_id}" v-for="message in messageArr" :key="message.id">
                                <div class="chat__avatar" v-if="message.admin_id && $mq==='desktop'">
                                    <img class="avatar avatar_md is-light" :src="`${myUrl}/source/images/support-chat/support-logo.svg`"
                                         alt="avatar support" width="52" height="52">
                                    <img class="avatar avatar_md is-dark" :src="`${myUrl}/source/images/support-chat/support-logo-dark.svg`"
                                         alt="avatar support" width="52" height="52">
                                </div>
                                <support-message v-if="!message.src"
                                                 :my-url="myUrl"
                                                 :message="message"
                                ></support-message>
<!--                                <div class="message message_base message_color1" v-if="!message.src">-->
<!--                                    <span class="message__text">-->
<!--                                        <span class="message__admin-name" v-if="message.admin_id">Admin:</span>-->
<!--                                        {{message.text}}-->
<!--                                    </span>-->
<!--                                    <check-read-opponent-->
<!--                                        :my-url="myUrl"-->
<!--                                        :time="message.created_at"-->
<!--                                        :is-read-opponent="true"-->
<!--                                    ></check-read-opponent>-->
<!--                                </div >-->
                                <span class="message message_media message_color1" v-else-if="isPicture(message.src)" @click="openChatMedia(message.src)">
                                    <span class="message__thumb">
                                        <img :src="`${s3Url}/${message.src}`" alt="">
                                    </span>
                                    <check-read-opponent
                                        :my-url="myUrl"
                                        :time="message.created_at"
                                        :is-read-opponent="true"
                                    ></check-read-opponent>

                                </span>
                                <span class="message message_simple" v-else>
                                    <a class="button button_sm button_border" :href="`${s3Url}/${message.src}`" download target="_blank">Download file</a>
                                    <check-read-opponent
                                        :my-url="myUrl"
                                        :time="message.created_at"
                                        :is-read-opponent="true"
                                    ></check-read-opponent>
                                </span>

                            </li>

                        </template>
                    </ul>
                </div>
                <div class="chat__footer">
                    <span class="chat__typing" v-if="supportTyping">Аdmin typing...</span>
                    <!--                                <span class="chat__message-blocked" style="display: none"><i class="icon icon_lock"></i> It's blocked</span>-->
                    <div class="chat__input">
                        <div class="ui-input" ref="inputSupportMessage"
                             contenteditable="true"
                             data-placeholder="Write your message..."
                             tabindex="-1"
                             @keypress="sendEventTyping"
                             @input="changeMessage"
                             @keyup.enter="sendSupportMessage"
                        ></div>
                        <button class="chat__attachment button button_attachment">
                            <i class="button__icon icon icon_attachment"></i>
                            <input type="file" :accept="getExts" @change="saveFile">
                        </button>
                        <div class="chat__buttons">
                            <button :class="{'button button_primary button_send': true, 'chat__send':$mq ==='desktop', 'button_round': $mq !=='desktop'}"  @click.prevent="sendSupportMessage">
                                <span><i class="button__icon icon icon_plane"></i><span class="button__text" v-if="$mq==='desktop'">Send</span></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CheckReadOpponent from "./CheckReadOpponent.vue";
import SupportMessage from "./SupportMessage.vue";
const month = ['','January','February','March','April','May','June','July','August','September','October','November','December'];

export default {
    name: "SupportChatContainer",
    components: {SupportMessage, CheckReadOpponent},
    props: [
        'supportMessages',
        'supportContact',
        'messageSize',
        'currentUser',
        // 'showSupport',
        'myUrl',
        's3Url',
        'lastSupportMessagesID',
        'isAllSupportMessages',
        'supportTyping',
        'windowHeight',
        'countUnread',
        'initSupportMessage'
    ],
    data(){
        return{
            message:'',
            isSendTyping:false,
            exts:['png','jpeg','jpg','svg','mp3','mp4','txt','pdf','doc','docx','xls','xlsx'],
            maxsize: 10*1024*1024,
            // previewMedia: null,
            fileVideo: null,
            fileVoice: null,
            mediaChat: [],
            indexMedia: null,
            messageTextMaxSize: 2000,
            isDisabled:false,
        }
    },
    computed:{
        getExts(){
            let accept = '';
            this.exts.forEach(ext=>{
                accept += `.${ext}, `
            });
            return accept;
        },
        messageTextMax() {
            return this.message.length < this. messageTextMaxSize
        },
    },
    mounted() {
        if (this.$mq !== 'desktop') this.$bus.$emit('get_support_messages');
        this.$bus.$on('support_chat_scroll_down',this.scrollDown);
        if (this.$refs.inputSupportMessage) {
            this.$refs.inputSupportMessage.addEventListener("paste", this.pasteToInput)
            if(this.initSupportMessage) this.$refs.inputSupportMessage.innerText = this.initSupportMessage
        }
        if(this.initSupportMessage) this.message = this.initSupportMessage;
    },
    beforeDestroy() {
        this.$bus.$off('support_chat_scroll_down',this.scrollDown);
        this.$refs.inputSupportMessage.removeEventListener("paste", this.pasteToInput)
    },
    methods:{
        pasteToInput(e) {
            e.preventDefault();
            let text = e.clipboardData.getData("text/plain");
            document.execCommand("insertHTML", false, text);
        },
        openMenu() {
            this.$emit('open_menu', false);
        },
        goToRequest() {
            this.$emit('go_to_request', false);
        },
        onScroll(event){
            if (!this.isAllSupportMessages && this.supportContact && this.lastSupportMessagesID){
                if (this.$refs.supportChatList.scrollTop === 0){
                    const oldHeight = this.$refs.supportChatList.scrollHeight ;
                    console.log('scroll top');
                    axios.get(`/get-more-support-messages/${this.supportContact.id}/${this.lastSupportMessagesID}`)
                        .then(response=>{
                            console.log(response.data);
                            this.$bus.$emit('add_more_support_message',response.data.messages);
                            this.$nextTick(()=>{
                                setTimeout(()=>{
                                    this.$refs.supportChatList.scrollTop = this.$refs.supportChatList.scrollHeight - oldHeight;
                                },250);
                            });
                        })
                        .catch(err => {
                            this.errorHandler(err);
                        });
                    // this.$bus.$emit('more_support_messages',this.$refs.supportChatList)
                }
            }
        },
        openChatMedia(src){
            console.log(src);
            this.mediaChat[0] = {src: `${this.s3Url}/${src}`}
            this.indexMedia = 0

            // this.$emit('open_chat_media', data);
        },
        // resetPreviewMedia(){
        //     this.isTextInput = true;
        //     this.previewMedia = null;
        //     this.fileImage = null;
        //     this.fileVideo = null;
        //     this.originVideo = null;
        //     this.fileVoice = null;
        //     this.mediaName = '' ;
        //     this.$refs.inputMessage.textContent = '';
        //     this.$refs.inputMessage.innerText = '';
        //     this.typeMessage = 'message';
        //     this.isConversion = false ;
        //
        // },
        messageTime(message){
            return new Date(message.created_at).toLocaleTimeString() ;
        },
        writeDate(date){
            const arr = date.split('.')
            return `${arr[0]} ${month[Number(arr[1])]}, ${arr[2]} `
        },
        isPicture(src){
            const ext = this.getExtantionFile(src);
            return ext === 'png' || ext === 'jpeg' || ext === 'jpg' || ext === 'svg'
        },
        changeMessage(event){
            this.message = event.target.innerText || event.target.textContent;
        },
        closeSupport() {
            console.log('closeSupport');
            if(this.$route.name === 'SupportChat') {
                this.$router.back()
            }
            this.$emit('close-support');
        },
        sendEventTyping(){
            if (!this.isSendTyping){
                this.$bus.$emit('is_typing_support');
                this.isSendTyping = true ;
                setTimeout(()=>{
                    this.isSendTyping = false ;
                },1500);
            }
        },
        scrollDown(){
            console.log('scrollDown');
            // console.log(this.$refs.chatOperatorContainer);
            // const  container = document.getElementById('chatoperatorcontainer') ;
            if (this.$refs.supportChatList) {
                setTimeout(()=>{
                    this.$refs.supportChatList.scrollTop = this.$refs.supportChatList.scrollHeight;
                },0)
            }
        },
        // classMessage(adminID){
        //     return adminID ? 'msg-start' : 'msg-end'
        // },
        sendSupportMessage(){

            console.log(this.message);
            if (!this.isDisabled && this.message && this.message.replace(/\s+/g, '').length !== 0) {
                if (this.messageTextMax ){
                    this.isDisabled = true;
                    axios.post(`/send-support-message/${this.currentUser.id}`,{
                        contact_id:this.supportContact.id,
                        text:this.message,
                        src:'',
                        admin_id:null,
                    })
                        .then(response => {
                            console.log(response.data);
                            this.isDisabled = false;
                            this.newMessageHandler(response.data)
                        })
                        .catch(e => {
                            console.log(e);
                            this.isDisabled = false;
                            this.errorHandler(e);
                        })
                }else {
                    alert('The message length cannot be longer than 2000 characters');
                }
            }
        },
        newMessageHandler(data){
            this.message = '';
            this.$refs.inputSupportMessage.textContent = '';
            this.$refs.inputSupportMessage.innerText = '';
            this.$bus.$emit('send_support_message',data);
            this.scrollDown();
        },
        errorHandler(err){
            this.$bus.$emit('error_handler',err);
        },
        getExtantionFile(fileName){
            let parts = fileName.split('.');
            let ext = '';
            if (parts.length > 1) {
                ext = parts.pop();
                return ext.toLowerCase();
            }
            return '';
        },
        saveFile(e){
            console.log('save file');
            if (this.isDisabled) return;
            // this.isRead = true;
            // clearTimeout(this.timer) ;
            let file = e.target.files[0];
            console.log(file);
            if (file.size > this.maxsize || file.fileSize > this.maxsize) {
                //show an alert to the user
                alert(`Allowed file size exceeded. (Max. ${this.maxsize / (1024 * 1024)} MB)`);
            }else if(this.exts.includes(this.getExtantionFile(file.name))){
                this.isDisabled = true;
                let data = new FormData();
                data.append('file', file);
                // data.append('name', file.name);
                data.append('contact_id',this.supportContact.id);
                data.append('admin_id',null);
                data.append('text', file.name);
                const config = {
                    headers: { 'content-type': 'multipart/form-data' }
                };

                axios.post('/send-file-support-chat' ,data, config)
                    .then(response=>{
                        console.log(response.data);
                        this.isDisabled = false;
                        if (response.data.message){
                            this.$bus.$emit('send_support_message',response.data);
                            this.scrollDown();
                        }
                    })
                    .catch(err => {
                        this.isDisabled = false;
                        this.errorHandler(err);
                    });
            }else {
                alert('This file type is not supported. Supported files - '+ this.exts.toString());
            }
            e.target.value = null;

        },

    }
}
</script>

<style scoped>

</style>
