var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page page_static page_payment-status"},[_c('section',{staticClass:"section section_page"},[_c('div',{staticClass:"container"},[(_vm.status === 'success')?[(_vm.status === 'success')?_c('h2',{staticClass:"section__heading heading"},[_vm._v("Successful Payment "),_c('i',{staticClass:"icon icon_md icon_secured"})]):_vm._e(),_vm._v(" "),_c('table',{staticClass:"table table_page page__table"},[_vm._m(0),_vm._v(" "),_c('tbody',[_c('tr',[_c('td',[_vm._v(_vm._s(_vm.datePayment))]),_vm._v(" "),_c('td',[_vm._v("Purchase of credits")]),_vm._v(" "),_c('td',{staticClass:"text-center"},[_c('span',{staticClass:"title"},[_vm._v("$ "+_vm._s(Number(_vm.amount/100).toFixed(2)))])])])])])]:(_vm.status ==='failed')?[_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),_c('p',{staticClass:"page__info"},[_vm._v("If all the rules have been followed, but the payment is still unsuccessful, contact your bank and find out what is the reason for your bank's refusal. You can also try using another credit card")])]:[_vm._m(3),_vm._v(" "),_vm._m(4)],_vm._v(" "),_c('span',{staticClass:"page__button button button_md button_primary",on:{"click":function($event){$event.preventDefault();return _vm.GoToGallery.apply(null, arguments)}}},[_c('span',[_vm._v("Apply")])]),_vm._v(" "),(_vm.inProgress)?_c('span',{staticClass:"page__button button button_md button_primary",on:{"click":function($event){$event.preventDefault();return _vm.goToFormCG.apply(null, arguments)}}},[_c('span',[_vm._v("Repeat")])]):_vm._e(),_vm._v(" "),_vm._m(5)],2)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("Data")]),_vm._v(" "),_c('th',[_vm._v("Description")]),_vm._v(" "),_c('th',{staticClass:"text-center"},[_vm._v("Amount")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"section__heading heading"},[_vm._v("Unsuccessful Payment "),_c('i',{staticClass:"icon icon_md icon_warning"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pad page__pad"},[_c('div',{staticClass:"pad__header"},[_c('span',[_vm._v("Help")])]),_vm._v(" "),_c('div',{staticClass:"pad__body"},[_c('ul',{staticClass:"list list_marked"},[_c('li',[_vm._v("Make sure you entered the correct card details and verification code.")]),_vm._v(" "),_c('li',[_vm._v("You must have enough money on your credit card.")]),_vm._v(" "),_c('li',[_vm._v("Make sure that the appropriate limits are set on your card before performing your payment. Your limit must not be less than the amount of the payment, and you must not have a limit on online payments.")]),_vm._v(" "),_c('li',[_vm._v("Check your mobile application, it may need to confirm the payment.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"section__heading heading"},[_vm._v("Payment is pending "),_c('i',{staticClass:"icon icon_md icon_warning"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pad page__pad"},[_c('div',{staticClass:"pad__header"},[_c('span',[_vm._v("Warning!!!")])]),_vm._v(" "),_c('div',{staticClass:"pad__body"},[_c('p',[_vm._v("\n                                Your payment is in progress, please wait for the transaction to complete, it may take some time\n                                Until your payment is processed, you cannot complete another transaction !\n                                You can return to the site while waiting\n                                We will notify you of the status of your payment\n                            ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page__footer"},[_c('p',[_c('span',[_vm._v("To contact us you can use "),_c('a',{staticClass:"page__link",attrs:{"href":"mailto:support@hablario.com"}},[_vm._v("support@hablario.com")])]),_vm._v(" "),_c('span',[_vm._v("To contact us you can use "),_c('a',{staticClass:"page__link",attrs:{"href":"mailto:support@cgbilling.com"}},[_vm._v("support@cgbilling.com")])])])])
}]

export { render, staticRenderFns }