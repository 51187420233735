<template>
    <div class="chat__footer">
        <menu-upload
            @upload_image="getFileImage"
            @upload_video="getFileVideo"
            @upload_voice="getFileVoice"
            @upload_gif="getFileGif"
            @toggle_emoji="toggleEmoji"
            ref="menuUpload"
            v-show="showMenuUpload"
        ></menu-upload>
        <div class="chat__caption" v-if="editMessage">
            <span><i class="icon icon_edit"></i> Edit message</span>
            <button class="button button_icon button_close" @click.prevent="endEditChatMessage"><i class="icon icon_close"></i></button>
        </div>
        <div class="chat__caption chat__caption_emoji" v-show="showEmoji">
            <emojis
                :my-url="myUrl"
                :emojis="emojis"
                @selectemoji="selectEmoji"
                ref="emojiList">
            </emojis>
        </div>
        <preview-media ref="previewMedia"
                       :my-url="myUrl"
                       :media="previewMedia"
                       :is-video="(typeMessage === 'clip')"
                       :is-voice="!!fileVoice"
                       :is-conversion-progress="isConversionProgress"
                       @close_preview="resetPreviewMedia"
                       v-if="previewMedia || isConversionProgress"
        ></preview-media>
        <div class="chat__preview preview" v-else-if="mediaName && !isCreateVoice" >
            <span class="name" >{{mediaName}}</span>
            <span class="close" @click.prevent="resetPreviewMedia">X</span>
        </div>
        <record-voice ref="recordVoice"
                      :my-url="myUrl"
                      @cancel_voice="cancelVoice"
                      @is_start_record="startRecord"
                      @progress_record="progressRecord"
                      @upload_voice="getFileVoice"
                      v-else-if="isCreateVoice">
        </record-voice>



        <span class="chat__typing" v-if="isTyping">{{opponentName}} typing ...</span>
        <span class="chat__message-blocked" v-if="isBlocked">
            <i class="icon icon_block-user"></i> It's blocked
        </span>
        <div class="chat__input" tabindex="-1" v-else>
            <button class="button button_letter" @click="toggleLetter" v-if="$mq==='desktop'">
                <i class="button__icon icon icon_envelope"></i>
            </button>
            <!--            data-placeholder="Write your message..."-->
            <template v-if="isCreateVoice">
                <div class="ui-input ui-input_record" contenteditable=false>
                    <!-- add class is-center if only Cancel-->
                    <span class="record" :class="{'is-center': !isStartRecord}">
                        <template  v-if="isStartRecord">
                            <span class="record__circle"></span>
                            <span class="record__label">{{timeRecord}}</span>
                            <span class="record__text">Record</span>
                        </template>
                        <span class="record__button" v-if="$mq!=='desktop'" @click.stop.capture="cancelVoice">Cancel</span>
                    </span>
                </div>
            </template>
            <template  v-else>
                <!--                data-placeholder="Type message ..."-->
                <div class="ui-input"  ref="inputMessage"
                     :contenteditable=isTextInput
                     data-placeholder="Type message ..."
                     @keypress="sendEventTyping"
                     @input="changeMessage"
                     @keyup.enter="sendMessage"
                     @click="clickInput"
                     tabindex="-1"
                ></div>
            </template>

            <!--            @blur="setChatHeight"-->
            <!--            @focus="setChatHeight"-->
            <button class="chat__attachment button button_attachment" :class="{disabled: isCreateVoice}"
                    @click.stop.capture="toggleShowMenu" ref="btnMenuUpload">
                <i class="button__icon icon icon_attachment"></i>
            </button>
            <div class="chat__buttons">
                <button class="chat__emoji button button_emoji" @click.stop.capture="toggleEmoji" ref="btnEmoji" v-if="$mq==='desktop'">
                    <i class="button__icon icon icon_emoji"></i>
                </button>

                <button class="button button_primary button_send" :class="{'chat__send':$mq ==='desktop', 'button_round': $mq !=='desktop','disabled': this.disabledSendMessage }"
                        @click.prevent="createVoice" v-if="!(isFilledMessage || fileImage || previewMedia || isCreateVoice )"  ref="btnVoiceRecord">
                    <span><i class="button__icon icon icon_mic-white"></i><span class="button__text" v-if="$mq==='desktop'">Voice</span></span>
                </button>

                <button class="button button_primary button_send" :class="{'chat__send':$mq ==='desktop', 'button_round': $mq !=='desktop','disabled': this.disabledSendMessage }"
                        @click.prevent="sendMessage" v-if="isFilledMessage || fileImage || previewMedia || fileVoice || isStartRecord ">
                    <span><i class="button__icon icon icon_plane"></i><span class="button__text" v-if="$mq==='desktop'">Send</span></span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Emojis from "../Emojis";
import MenuUpload from "../MenuUpload";
import PreviewMedia from "./PreviewMedia";
import CustomAudioPlayer from "../newModules/CustomAudioPlayer.vue";
import RecordVoice from "./RecordVoice.vue";

let isSendVoice = false;
let fileVideo = null;
let originVideo = null;
let mp4BoxFile = null;
export default {
    name: "ChatInput",
    components: {
        RecordVoice,
        CustomAudioPlayer,
        MenuUpload,
        Emojis,
        PreviewMedia
    },
    props:[
        'myUrl',
        'emojis',
        'isTyping',
        'opponentName',
        'opponentID',
        'showBtnDown',
        'isOpponentInChat',
        'isBlocked',
        'isConversionProgress',
        'pathTempFile',
        'editMessage',
        'usedVideoCodecs',
        'supportsLocalStorage',
        'disabledSendMessage'
    ],
    data(){
        return{
            showMenuUpload: false,
            showEmoji: false,
            newMessage: '',
            isExtend: false,
            isSendTyping:false,
            isTextInput: true,
            previewMedia: null,
            fileImage: null,
            // fileVideo: null,
            // originVideo:null,
            fileVoice: null,
            maxsizeImage: 10*1024*1024,
            thumbnailHeight:270,
            image: null,
            typeMessage:'message',
            poster:null,
            mediaName:'',
            isConversion:false,
            messageTextMaxSize: 2000,
            isStartRecord: false,
            isCreateVoice: false,
            timeRecord: '00:00,0',
            // disabledButton: false,
            // isLocalStorage:false,
            // isConversionProgress:false,
        }
    },
    created() {
        // if (this.$mq ==='desktop') {
        //     setTimeout(()=>{
        //         this.$refs.inputMessage.focus();
        //     },500);
        // }
        //TODO check
        // document.addEventListener('click', this.handleOutsideClick);
        // document.addEventListener('touchstart', this.handleOutsideClick);
    },
    mounted() {
        this.$bus.$on('set_message_input',this.setMessageInInput);
        this.$bus.$on('close_chat_menu_upload',this.closeMenuUpload);
        // this.$bus.$on('undisabled_button_chat',this.undisabledButton);
        // if (this.$mq ==='desktop') {
        //     const labelInputRef = this.$refs.inputMessage
        //     if(labelInputRef) labelInputRef.focus();
        // }
        const inputEl = this.$refs.inputMessage;
        if(inputEl){
            console.log('this.$refs.inputMessage')
            inputEl.addEventListener("paste", this.pasteToInput);
            // this.$refs.inputMessage.click();
            // this.$refs.inputMessage.scrollIntoView();
            // window.location.hash = '#inputMessage';
            if(this.$mq ==='desktop'){
                setTimeout(()=>{
                    if(inputEl) inputEl.focus();
                },1000)
            }
        }
        // this.isLocalStorage = this.supportsLocalStorage();
        this.getCacheMessage();

        // включаем когда идет запись
        // this.initProgressBar()
    },
    beforeDestroy() {
        this.$bus.$off('set_message_input',this.setMessageInInput);
        this.$bus.$off('close_chat_menu_upload',this.closeMenuUpload);
        // this.$bus.$off('undisabled_button_chat',this.undisabledButton);
        //TODO check
        // document.removeEventListener('click', this.handleOutsideClick);
        // document.removeEventListener('touchstart', this.handleOutsideClick);
        if (this.$refs.inputMessage){
            this.$refs.inputMessage.removeEventListener("paste", this.pasteToInput)
        }
        this.resetPreviewMedia()
    },
    computed: {
        messageTextMax() {
            return this.newMessage.length < this. messageTextMaxSize
        },
        isFilledMessage(){
            if(!this.newMessage) return false
            const message = this.newMessage.trim();
            return (message && message.length)
        }
    },
    watch: {
        editMessage(editMessage){
            if(editMessage){
                this.editChatMessage(editMessage.message,editMessage.type);
            }
        },
        opponentID(opponentID){
            console.log('new opponent',opponentID);
            this.getCacheMessage();
        }
    },
    methods:{
        clickInput(){
            const input = this.$refs.inputMessage
            if(input && this.$mq !== 'desktop'){
                setTimeout(() => {
                    input.scrollIntoView({ block: "center",inline: "start", behavior: "smooth" })
                },1000)
            }
        },
        // undisabledButton(){
        //     this.disabledButton = false
        // },
        createVoice(){
            this.isCreateVoice = true;
        },
        cancelVoice(){
            this.isCreateVoice = false;
            this.isStartRecord = false;
            this.resetPreviewMedia();
        },
        startRecord(status){
            console.log('startRecord()')
            this.isStartRecord = status;
        },
        progressRecord(time){
            // console.log(time)
            const formattedTime = [
                Math.floor((time % 3600000) / 60000), // minutes
                Math.floor((time % 60000) / 1000), // seconds
                Math.floor((time % 1000) / 10), // milli
            ].map((v) => (v < 10 ? '0' + v : v));
            this.timeRecord = `${formattedTime[0]}:${formattedTime[1]},${formattedTime[2]}`
            // console.log(this.timeRecord)
        },


        // clickInputMessage(){
        //     console.log('clickInputMessage');
        //     this.setMessageInInput('')
        // },

        // setChatHeight() {
        //     console.log('setChatHeight', window.innerHeight);
        //     setTimeout(()=>{
        //         const root = document.documentElement;
        //         root.style.setProperty('--vh', `${window.innerHeight}px`);
        //         window.scrollTo(0,0);
        //
        //     },400)
        //
        // },
        closeMenuUpload(){
            this.showMenuUpload = false;
            this.showEmoji = false;
        },
        getCacheMessage(){
            if(this.supportsLocalStorage){
                let message = localStorage[`message.${this.opponentID}`]
                if(message) message = message.trim();
                this.setMessageInInput(message? message: '');
                if(!message)localStorage.removeItem(`message.${this.opponentID}`)
            }
        },
        endEditChatMessage(){
            this.$emit('end_edit_chat_message');
            this.editChatMessage('','message');
        },
        editChatMessage(message,type) {
            console.log('editChatMessage');
            this.setMessageInInput(message);
            this.typeMessage = type;
        },
        setMessageInInput(message){
            const el = this.$refs.inputMessage;
            if(!el) return;

            if (typeof el.innerText !== 'undefined') {
                el.innerText = message;
            } else {
                el.textContent = message;
            }
            this.newMessage = message;
        },
        pasteToInput(e) {
            e.preventDefault();
            let text = e.clipboardData.getData("text/plain");
            document.execCommand("insertHTML", false, text);
        },
        // handleOutsideClick(event) {
        //     if (!this.isBlocked && this.$refs.btnEmoji) {
        //         if (!(this.$refs.btnEmoji == event.target
        //             || this.$refs.btnEmoji.contains(event.target)
        //             || this.$refs.emojiTabs == event.target
        //             || this.$refs.emojiTabs.contains(event.target)
        //             || this.$refs.emojiList.$el == event.target
        //             || this.$refs.emojiList.$el.contains(event.target))) {
        //             this.showEmoji = false
        //         }
        //         if(!this.$refs.menuUpload || !this.$refs.btnMenuUpload)return
        //         if (!(this.$refs.menuUpload.$el == event.target
        //             || this.$refs.menuUpload.$el.contains(event.target)
        //             || this.$refs.btnMenuUpload == event.target
        //             || this.$refs.btnMenuUpload.contains(event.target))) {
        //             this.showMenuUpload = false
        //         }
        //     }
        // },
        getExtantionFile(fileName){
            let parts = fileName.split('.');
            let ext = '';
            if (parts.length > 1) {
                ext = parts.pop();
                return ext.toLowerCase();
            }
            return '';
        },
        getFileVoice(data){
            console.log(data);
            const size = data.file.size
            console.log({size: data.file.size/1024/1024})
            // this.previewMedia = data.preview;
            this.fileVoice = data.file;
            this.mediaName = data.file.name ;
            this.typeMessage = 'voice'
            fileVideo = null;
            this.fileImage = null;
            this.isTextInput = false ;
            this.showMenuUpload = false;
            console.log('mediaName - '+ this.mediaName );
            const ext = this.getExtantionFile(this.mediaName);

            if(isSendVoice) this.$nextTick(()=>{ this.sendMessage() })
        },
        getFileGif(data){
            console.log(data);

            if (this.getExtantionFile(data.file.name) === 'gif'){
                this.previewMedia = data.preview;
                this.fileImage = data.file;
                this.mediaName = data.file.name ;
                this.typeMessage = 'gif'
                fileVideo = null;
                this.fileVoice = null;
                this.isTextInput = false ;
                this.showMenuUpload = false;
            } else {
                alert('This format is not gif!');
            }
        },
        sendVoice(){
            // if (this.isConversion){
            //     if (this.pathTempFile){
            //         this.$bus.$emit('upload_voice',this.pathTempFile,this.isOpponentInChat,'convert','mp3');
            //     }else {
            //         alert('File conversion not successful, please select another file');
            //     }
            // }else {
            //     this.$bus.$emit('upload_voice',this.fileVoice,this.isOpponentInChat,'origin',this.getExtantionFile(this.mediaName));
            // }
            // this.disabledButton = true;
            this.$bus.$emit('upload_voice',this.fileVoice,this.isOpponentInChat,'origin',this.getExtantionFile(this.mediaName));
            this.cancelVoice();
        },
        sendGif(){
            this.$bus.$emit('upload_gif',this.fileImage,this.isOpponentInChat);
            this.resetPreviewMedia();
        },
        getFileVideo(data){
            this.previewMedia = data.preview;
            this.mediaName = data.file.name ;
            fileVideo = new FormData();
            // fileVideo.append('video',data.file,'video');
            originVideo = null;
            fileVideo.append('originName',data.file.name);
            this.fileImage = null;
            this.isTextInput = false ;
            this.showMenuUpload = false;
            this.typeMessage = 'clip'
            console.log(data);
            const ext = this.getExtantionFile(this.mediaName);
            fileVideo.append('ext',ext);
            //this.checkValidMimeType(data.file)
            this.checkValidCodec(data.file,ext)
        },
        startVideoScreenshot(){
            console.log('startVideoScreenshot')
            this.shoot()
        },
        checkValidCodec(file,ext){
            console.log('checkValidCodec')
            if( ext !== 'mp4' || !(window.FileReader && window.Blob)){
                this.sendConversationMedia(file,'clip',ext)
                return
            }

            console.log('window.FileReader')
            originVideo = new File([file], file.name, { type: file.type });
            const fileReader = new FileReader();
            console.log('fileReader');
            fileReader.onloadend = (e) => {
                console.log('fileReader.onloadend');
                const arrayBuffer = e.target.result
                arrayBuffer.fileStart = 0
                console.log('byteLength',arrayBuffer.byteLength)
                mp4BoxFile = mp4box.createFile()
                mp4BoxFile.onError = (e)=> {
                    console.error('mp4BoxFile.onError')
                    console.error(e)
                    this.sendConversationMedia(file,'clip',ext)
                }
                mp4BoxFile.onReady = (info) => {
                    console.log(info)
                    const track = info.tracks.find(item => item.type === "video")
                    // console.log('track',track)
                    const codec = track.codec.split('.')[0]     //"avc1.64001f"
                    console.log('codec',codec)

                    if(codec && this.usedVideoCodecs && this.usedVideoCodecs.length && this.usedVideoCodecs.includes(codec.toLowerCase())){
                        this.startVideoScreenshot()
                    }else {
                        this.sendConversationMedia(file,'clip',ext)
                    }
                }
                mp4BoxFile.appendBuffer(arrayBuffer);
            }
            fileReader.readAsArrayBuffer(file);
            console.log('fileReader.readAsArrayBuffer')
        },
        sendConversationMedia(file,type,ext){
            this.$emit('is_conversion_progress',true);
            this.isConversion = true;
            this.previewMedia = null;
            this.$bus.$emit('convert_media',file,type,ext);
        },
        conversionCompleted(previewMedia){
            this.previewMedia = previewMedia;
            this.$nextTick(()=>{
                this.startVideoScreenshot()
            })
        },
        shoot() {
            const video = document.getElementById('videoPreview');
            if(video){
                video.addEventListener('loadeddata', (event) => {
                    console.log('video.loadeddata');

                    setTimeout(() => {
                        this.captureVideo(video)
                    }, 500);
                });
            }else{
                console.log('shoot !video')
                fileVideo.append("poster",'');
                // this.previewMedia = null;
            }
        },
        captureVideo(video){
            const w = video.videoWidth * 0.25;
            const h = video.videoHeight * 0.25;
            const canvas = document.createElement('canvas');
            canvas.width  = w;
            canvas.height = h;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(video, 0, 0, w, h);
            // const canvas = await this.captureVideo(video);
            // const blob = await new Promise(resolve => canvas.toBlob(resolve, "image/jpeg", 1));
            canvas.toBlob((blob) => {
                if (blob){
                    console.log('add poster success')
                    fileVideo.append("poster",blob,'poster');
                }else {
                    console.log('shoot !blob')
                    fileVideo.append("poster",'');
                }
            }, "image/jpeg", 1)
        },
        // captureVideo(video){
        //     const w = video.videoWidth * 0.25;
        //     const h = video.videoHeight * 0.25;
        //     const canvas = document.createElement('canvas');
        //     canvas.width  = w;
        //     canvas.height = h;
        //     const ctx = canvas.getContext('2d');
        //     ctx.drawImage(video, 0, 0, w, h);
        //
        //     return canvas;
        // },
        sendVideo(){
            console.log('sendVideo')
            if(this.previewMedia){
                URL.revokeObjectURL(this.previewMedia)
                this.previewMedia = null;
                console.log('delete previewMedia');
            }
            if (this.isConversion){
                if (this.pathTempFile){
                    fileVideo.append('path',this.pathTempFile);
                    fileVideo.append('convert','convert');
                    // this.disabledButton = true;
                    this.$bus.$emit('upload_video',fileVideo,this.isOpponentInChat);
                }else {
                    alert('File conversion not successful, please select another file');
                }
            }else {
                fileVideo.append('video',originVideo,'video');
                fileVideo.append('convert','origin');
                // this.disabledButton = true;
                this.$bus.$emit('upload_video',fileVideo,this.isOpponentInChat);
            }

            this.resetPreviewMedia();
        },
        toggleLetter(){
            this.$emit('toggle_letter')
        },
        resetPreviewMedia(){
            isSendVoice = false;
            this.isTextInput = true;
            if(this.previewMedia) URL.revokeObjectURL(this.previewMedia);
            if(mp4BoxFile && typeof mp4BoxFile.flush === 'function'){
                mp4BoxFile.flush()
                mp4BoxFile = null;
            }
            this.previewMedia = null;
            this.fileImage = null;
            fileVideo = null;
            originVideo = null;
            this.fileVoice = null;
            this.mediaName = '' ;
            if(this.$refs.inputMessage) this.$refs.inputMessage.textContent = '';
            if(this.$refs.inputMessage) this.$refs.inputMessage.innerText = '';
            this.typeMessage = 'message';
            this.isConversion = false ;
            this.$emit('is_conversion_progress',false);
        },
        convertWithThumbnail(file){
            let output = {};
            let fileOriginal = null;
            let jpegOriginal = null;
            URL.revokeObjectURL(this.image.src);

            const canvas = document.createElement("canvas"),
                ctx = canvas.getContext("2d");
            canvas.width = this.image.width;
            canvas.height = this.image.height;
            ctx.drawImage(this.image, 0, 0);
            canvas.toBlob((blob) => {
                fileOriginal = new File([blob], file.name, {
                    type: "application/octet-stream"
                });
                jpegOriginal = canvas.toDataURL("image/jpeg", .95);
                this.makeThumb(jpegOriginal, fileOriginal, output)
            }, "image/jpeg", .95)

        },
        makeThumb(jpegOriginal, fileOriginal, output){
            let fileThumb = null;
            const canvasThumb = document.createElement("canvas"),
                ctxThumb = canvasThumb.getContext("2d");
            const height = this.thumbnailHeight;
            const scaleFactor = height / this.image.height;
            canvasThumb.height = height;
            canvasThumb.width = this.image.width * scaleFactor;
            ctxThumb.drawImage(this.image, 0, 0, this.image.width * scaleFactor, this.thumbnailHeight);
            canvasThumb.toBlob((blob) => {
                fileThumb = new File([blob], 'thumb', {
                    type: "application/octet-stream"
                });
                output['image'] = jpegOriginal;
                output['file'] = fileOriginal;
                output['thumb'] = fileThumb;
                // this.disabledButton = true;
                this.$bus.$emit('send_chat_photo', output,this.isOpponentInChat);
                this.resetPreviewMedia();
            }, "image/jpeg", 0.75)
        },

        sendPicture() {
            this.image = null;
            if (this.fileImage.size > this.maxsizeImage || this.fileImage.fileSize > this.maxsizeImage) {
                //show an alert to the user
                alert(`Allowed file size exceeded. (Max. ${this.maxsizeImage/ (1024*1024)} MB)`);
            } else {
                this.image = new Image;

                const ext = this.getExtantionFile(this.fileImage.name);
                if (ext === 'png' || ext === 'jpeg' || ext === 'jpg' || ext === 'svg'){
                    this.image.onload = () => {
                        this.convertWithThumbnail(this.fileImage)
                    };
                    this.image.src = URL.createObjectURL(this.fileImage)

                } else {
                    alert('This format is not supported!\n' +
                        'Supported formats .png .jpeg .jpg .svg');
                }
            }
        },
        selectEmoji(fileName){
            this.$bus.$emit('send_chat_emoji', fileName,this.isOpponentInChat);
            this.showEmoji = false;
        },
        scrollDown(animate) {
            this.$bus.$emit('chat_scroll_down', animate)
        },
        toggleEmoji(){
            this.showEmoji = !this.showEmoji ;
            if(this.showEmoji) this.showMenuUpload = false;
        },
        sendMessage(){
            if(this.isStartRecord){
                if(this.$refs.recordVoice) this.$refs.recordVoice.stopRecord()
                isSendVoice = true
                return;
            }
            const typeMess = this.typeMessage;
            this.typeMessage = 'message';
            console.log('sendMessage');
            switch (typeMess){
                case "gif":
                    this.sendGif();
                    break ;
                case "voice":
                    this.sendVoice();
                    break ;
                case "clip":
                    this.sendVideo();
                    break ;
                case "picture":
                    this.sendPicture();
                    break ;
                case "message":
                    if (this.messageTextMax) this.sendText();
                    else alert('The message length cannot be longer than 2000 characters');
                    break;
            }
        },
        sendText(){
            if (this.newMessage && this.newMessage.replace(/\s+/g, '').length !== 0){
                if(this.editMessage){
                    console.log('send edit Message');
                    this.$bus.$emit('update_edit_message', this.newMessage,this.editMessage.id);
                    this.$emit('end_edit_chat_message');
                }else {
                    console.log('sendMessage');
                    this.$bus.$emit('send_message', this.newMessage,this.isOpponentInChat);
                }
                // this.$refs.inputMessage.textContent = '';
                // this.$refs.inputMessage.innerText = '';
                // this.newMessage = '';
            } else {
                alert('The text should not contain only spaces');
            }
        },
        removeChars(str) {
            const charsToRemove = ['<', '>', '&', '|', '@']
            const regex = new RegExp(`[${charsToRemove.join('')}]`, 'g');

            return str.replace(regex, ' ');
        },
        changeMessage(event){
            let message = event.target.innerText || event.target.textContent;
            if(message && message.length > 2000 && this.newMessage && this.newMessage.length < message.length){
                this.setMessageInInput(this.newMessage)
                alert('The message length cannot be longer than 2000 characters')
                return;
            }
            message = this.removeChars(message)
            this.newMessage = message;
            if(message){
                message = message.trim()
                if(message) localStorage[`message.${this.opponentID}`] = message;
                else localStorage.removeItem(`message.${this.opponentID}`)
            }
        },
        sendEventTyping(){
            if (this.isOpponentInChat && !this.isSendTyping){
                console.log('sendEventTyping');
                this.$emit('is_typing');
                this.isSendTyping = true ;
                setTimeout(()=>{
                    this.isSendTyping = false ;
                },1500);
            }
        },
        toggleShowMenu(){
            if(this.isCreateVoice) return
            this.showMenuUpload =  !this.showMenuUpload ;
            if(this.showMenuUpload) this.showEmoji = false;
        },
        newLetter(){
            this.$emit('newletter');
        },
        getFileImage(data) {
            this.previewMedia = data.preview;
            this.fileImage = data.file;
            this.mediaName = data.file.name ;
            fileVideo = null;
            this.isTextInput = false ;
            this.showMenuUpload = false;
            this.typeMessage = 'picture'
            console.log(data);
        },
        // checkValidMimeType(file){
        //     if (window.FileReader && window.Blob) {
        //         const mimeTypeList = (this.mimeTypeList && this.mimeTypeList.length) ? this.mimeTypeList : ['667479706d7034','6674797069736f'];
        //         console.log('Loaded file: ' + file.name);
        //         console.log('Blob mime: ' + file.type);
        //
        //         const fileReader = new FileReader();
        //         fileReader.onloadend = (e)=>{
        //             const arr2 = ( new Uint8Array(e.target.result)).subarray(4, 11);
        //             let header2 = '';
        //
        //             for (let i = 0; i < arr2.length; i++) {
        //                 header2 += arr2[i].toString(16);
        //             }
        //             console.log('File header2: ' + header2);
        //
        //             const ext = this.getExtantionFile(file.name);
        //
        //             if( ext !== 'mp4' || mimeTypeList.includes(header2)){
        //                 this.$emit('is_conversion_progress',true);
        //                 this.isConversion = true;
        //                 this.$bus.$emit('convert_media',file,'clip',ext);
        //             }
        //         };
        //         fileReader.readAsArrayBuffer(file);
        //     }else {
        //         console.log('not window.FileReader && window.Blob');
        //     }
        // },
    }
}
</script>
