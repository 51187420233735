<template>
    <div class="message message_base message_color1">
        <svg class="message__triangle" xmlns="http://www.w3.org/2000/svg" width="12"
             height="20" fill="none">
            <path fill="currentColor"
                  d="m0 0 11.4209 15.3808C12.8242 17.2706 11.518 20 9.21035 20H0V0Z"/>
        </svg>
        <span class="message__text is-inline-flex" v-if="message.type && message.type==='failed_payment'">
            <span class="message__admin-name">Admin:</span>
            <span class="message__information">
                <span class="message__subtitle">Failed payment!</span>
                <span class="message__description">
                    <span>{{amount}}.</span>
                    <span> {{errMessage}}</span>
                </span>
                <span v-if="message.description">{{message.description}}</span>
            </span>
        </span>
        <span class="message__text" v-else>
            <span class="message__admin-name" v-if="message.admin_id">Admin:</span>
            {{message.text}}
        </span>
        <check-read-opponent
            :my-url="myUrl"
            :time="message.created_at"
            :is-read-opponent="true"
        ></check-read-opponent>
    </div >

</template>

<script>
import CheckReadOpponent from "./CheckReadOpponent.vue";

export default {
    name: "SupportMessage",
    components: { CheckReadOpponent },
    props:['myUrl','message'],
    data(){
        return{
            amount: '',
            errMessage: '',
        }
    },
    computed:{
        amount(){
            return this.message && this.message.type==='failed_payment'
        }
    },
    mounted() {
        if (this.message && this.message.type==='failed_payment' && this.message.text && this.message.text.length){
            const arr = this.message.text.split('_')
            if(arr.length===2){
                this.amount=arr[0]
                this.errMessage=arr[1]
            }else {
                this.errMessage=this.message.text
            }
        }
    }
}
</script>


<style scoped>

</style>
