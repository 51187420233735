var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section section_intro section_welcome",style:(`min-height: ${_vm.windowHeight}px`)},[_c('img',{ref:"image",staticClass:"video video_responsive",attrs:{"src":_vm.imageLendingSrc}}),_vm._v(" "),_c('div',{staticClass:"section__caption",class:{'section__caption_romantic' : _vm.region === 'romantic',
             'section__caption_friends' : _vm.region === 'friends',
             'section__caption_italy' : _vm.region === 'italy',
             'section__caption_girls' : _vm.region === 'girls',
             'section__caption_over' : _vm.region === 'over',
             'section__caption_mistress' : _vm.region === 'mistress',
             'section__caption_nurse' : _vm.region === 'nurse',
             'section__caption_adult' : _vm.region === 'adult',
             'section__caption_relationship' : _vm.region === 'relationship'}},[(_vm.region === 'texas')?_c('div',{staticClass:"content",class:{'content_texas' : _vm.region === 'texas'}},[_vm._m(0),_vm._v(" "),_vm._m(1)]):_vm._e(),_vm._v(" "),(_vm.region === 'romantic')?_c('div',{staticClass:"content",class:{'content_romantic' : _vm.region === 'romantic'}},[_vm._m(2)]):_vm._e(),_vm._v(" "),(_vm.region === 'relationship')?_c('div',{staticClass:"content",class:{'content_relationship' : _vm.region === 'relationship'}},[_vm._m(3),_vm._v(" "),_vm._m(4)]):_vm._e(),_vm._v(" "),(_vm.region === 'friends')?_c('div',{staticClass:"content",class:{'content_friends' : _vm.region === 'friends'}},[_vm._m(5)]):_vm._e(),_vm._v(" "),(_vm.region === 'italy')?_c('div',{staticClass:"content",class:{'content_italy' : _vm.region === 'italy'}},[_vm._m(6),_vm._v(" "),_vm._m(7)]):_vm._e(),_vm._v(" "),(_vm.region === 'girls')?_c('div',{staticClass:"content",class:{'content_girls' : _vm.region === 'girls'}},[_vm._m(8)]):_vm._e(),_vm._v(" "),(_vm.region === 'over')?_c('div',{staticClass:"content",class:{'content_over' : _vm.region === 'over'}},[_vm._m(9)]):_vm._e(),_vm._v(" "),(_vm.region === 'mistress')?_c('div',{staticClass:"content",class:{'content_mistress' : _vm.region === 'mistress'}},[_vm._m(10)]):_vm._e(),_vm._v(" "),(_vm.region === 'nurse')?_c('div',{staticClass:"content",class:{'content_nurse' : _vm.region === 'nurse'}},[_vm._m(11)]):_vm._e(),_vm._v(" "),(_vm.region === 'adult')?_c('div',{staticClass:"content",class:{'content_adult' : _vm.region === 'adult'}},[_vm._m(12)]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"title is-text-animation"},[_c('span',[_vm._v("Hello")]),_vm._v(" "),_c('span',[_vm._v("Texan")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"text"},[_c('span',[_vm._v("I want to")]),_vm._v(" "),_c('span',[_vm._v("talk to you")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"title"},[_c('span',{staticClass:"heading"},[_vm._v("The best "),_c('span',[_vm._v("place")])]),_vm._v(" "),_c('span',{staticClass:"is-first"},[_vm._v("for romantic "),_c('span',[_vm._v("messaging")])]),_vm._v(" "),_c('span',{staticClass:"is-second"},[_vm._v("with "),_c('span',[_vm._v("single "),_c('span',[_vm._v("women")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"title"},[_c('span',{staticClass:"is-first"},[_vm._v("The best "),_c('span',[_vm._v("place")])]),_vm._v(" "),_c('span',{staticClass:"is-second"},[_vm._v("for sexting")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"text"},[_c('span',{staticClass:"is-first"},[_vm._v("with horny")]),_vm._v(" "),_c('span',{staticClass:"is-second"},[_vm._v("single women")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"title"},[_c('span',{staticClass:"is-first"},[_vm._v("The best place "),_c('span',[_vm._v("for hot datings")])]),_vm._v(" "),_c('span',{staticClass:"is-second"},[_vm._v("With "),_c('span',[_vm._v("single women")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"title"},[_c('span',{staticClass:"is-first"},[_vm._v("The Best "),_c('span',[_vm._v("Place")])]),_vm._v(" "),_c('span',{staticClass:"is-second"},[_vm._v("for hot "),_c('span',[_vm._v("messaging")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"text"},[_vm._v("with single "),_c('span',[_vm._v("Italian Women")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"title"},[_c('span',{staticClass:"is-first"},[_vm._v("The Best Place "),_c('span',[_vm._v("to chat")])]),_vm._v(" "),_c('span',{staticClass:"is-second"},[_vm._v("with "),_c('span',[_vm._v("single girls")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"title"},[_c('span',{staticClass:"is-first"},[_vm._v("The Best Place "),_c('span',[_vm._v("for men over 60")])]),_vm._v(" "),_c('span',{staticClass:"is-second"},[_vm._v("for messaging "),_c('span',[_vm._v("with Single Women")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"title"},[_c('span',{staticClass:"is-first"},[_c('span',[_vm._v("The best place")]),_vm._v(" "),_c('span',[_vm._v("for school "),_c('span',[_vm._v("mistress")])])]),_vm._v(" "),_c('span',{staticClass:"is-third"},[_c('span',[_vm._v("role-playing")]),_vm._v(" "),_c('span',[_vm._v("sexting games")])]),_vm._v(" "),_c('span',{staticClass:"is-second"},[_vm._v("with "),_c('span',[_vm._v("Single Women")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"title"},[_c('span',{staticClass:"is-first"},[_c('span',[_vm._v("The best place")]),_c('span',[_vm._v("to role-playing")])]),_vm._v(" "),_c('span',{staticClass:"is-third"},[_c('span',[_vm._v("a sexy nurse")]),_vm._v(" "),_c('span',[_vm._v("by texting")])]),_vm._v(" "),_c('span',{staticClass:"is-second"},[_vm._v("with "),_c('span',[_vm._v("Single Women")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"title"},[_c('span',{staticClass:"is-first"},[_c('span',[_vm._v("The best place")]),_vm._v(" "),_c('span',[_c('span',[_vm._v("for")]),_vm._v(" adult messaging")])]),_vm._v(" "),_c('span',{staticClass:"is-second"},[_vm._v("with "),_c('span',[_vm._v("Single Women")])])])
}]

export { render, staticRenderFns }