require('./bootstrap')

import Vue from 'vue'
import VueRouter from "vue-router"
import router from "./router"


import Snotify, { SnotifyPosition } from 'vue-snotify'
import "vue-snotify/styles/material.css";
import VueEllipseProgress from 'vue-ellipse-progress';
import VueMq from 'vue-mq';
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import {Hooper, Navigation as HooperNavigation, Pagination as HooperPagination, Slide} from "hooper";
import 'hooper/dist/hooper.css';


import LazyLoadDirective from "./directives/LazyLoadDirective";
import ScrollDirective from "./directives/ScrollDirective";
import ContactUs from "./components/landing/ContactUs";
import Login from "./components/auth/login";
import Register from "./components/auth/register";
import LandingVideo from './components/landing/LandingVideo';
import LandingVideoBox from './components/landing/LandingVideoBox';
import LandingImage from './components/landing/LandingImage';
import LandingImageOld from "./components/landing/LandingImageOld.vue";
import LandingImages from './components/landing/LandingImages';
import SubscribeBlog from "./components/blog/SubscribeBlog";
import AddNewBlog from "./components/blog/AddNewBlog";
import MenuStaticPage from "./components/modules/MenuStaticPage";
import ModalPromo from "./components/newModules/modals/ModalPromo.vue";
import ForgotPassword from './components/landing/ForgotPassword'
import LikeStory from './components/newModules/LikeStory.vue'
import InstallDesktop from './components/InstallDesktop'
import AnimeEasy from './components/landing/AnimeEasy'
import VuePortal from '@linusborg/vue-simple-portal'
import carousel from "./components/newModules/carousel.vue";
import VideoBlock from "./components/landing/VideoBlock.vue";
import Echo from "laravel-echo";
import * as PusherPushNotifications from "@pusher/push-notifications-web";

// import Navbar from './components/newModules/Navbar'

// import PushNotification from './components/PushNotification'
Object.defineProperty(Vue.prototype,"$bus",{
    get: function() {
        return this.$root.bus;
    }
});


let options = {
    toast: {
        position: SnotifyPosition.rightTop
    }
}
const debounce = (originalFn, timeoutMs)=>{
    let timeout;
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => originalFn(...args), timeoutMs);
    };
}
Vue.config.errorHandler = (err, vm, info) => {
    console.error('client-render-error')
    console.error(err)
    const currentUrl = window.location.href
    axios.post('/client-render-error',{
        info,
        typeApp: window.typeApp ? window.typeApp : 'undefined',
        currentUrl,
        message: err.message,
        stack: err.stack,
        component: vm.$options.name || vm.$options._componentTag || 'anonymous',
    })
        .then(response =>{
            console.log(response.data)
        })
        .catch(err => {
            console.log(err)
        })
}
Vue
    .use(Snotify, options)
    .use(VueRouter)
    .use(VueEllipseProgress, "vep")
    .use(CoolLightBox)
    .use(VueMq, {
        breakpoints: {
            mobile: 576,
            tablet: 1024,
            laptop: 1280,
            desktop: Infinity
        }
    })
    .use(VuePortal, {
        name: 'portal', // optional, use to rename component
    })

Vue.directive("lazyload", LazyLoadDirective)
Vue.directive("scroll", ScrollDirective)
Vue.component('hooper', Hooper)
Vue.component('slide', Slide)
Vue.component('hooper-navigation', HooperNavigation)
Vue.component('hooper-pagination', HooperPagination)
Vue.component('coollightbox', CoolLightBox)

Vue.config.errorHandler = (err, vm, info) => {
    console.error('client-render-error')
    console.error(err)
    const currentUrl = window.location.href
    axios.post('/client-render-error',{
        info,
        typeApp: window.typeApp ? window.typeApp : 'undefined',
        currentUrl,
        message: err.message,
        stack: err.stack,
        component: vm.$options.name || vm.$options._componentTag || 'anonymous',
    })
        .then(response =>{
            console.log(response.data)
        })
        .catch(err => {
            console.log(err)
        })
}


let classList = null;
let videoEl = null;
let timeOutRegisterServiceWorker = false;

const app = new Vue({
    el: '#app',
    router,

    components: {
        ContactUs,
        Login,
        Register,
        ForgotPassword,
        LandingVideo,
        LandingVideoBox,
        LandingImage,
        LandingImageOld,
        LandingImages,
        InstallDesktop,
        SubscribeBlog,
        AddNewBlog,
        MenuStaticPage,
        AnimeEasy,
        LikeStory,
        carousel,
        VideoBlock,
        ModalPromo
    },
    data(){
        return {
            currentUser: null,
            myUrl:'',
            cdnUrl:'',
            bus: new Vue({}),
            progressLoading: true,
            isGlobalLoader: true,
            version:'',
            buildTimestamp: BUILD_TIMESTAMP,
            modalConnectError: false,
            visitWoman:null,
            indexMedia: null,
            mediaStory: [],
            modalWelcome: false,
            windowWidth: null,
            windowHeight: null,

            // new design
            isLogin: false,
            isBlurMain: false,
            isOpenMenuMobile: false,
            showLogin: false,
            showRegister: false,
            showContactUs:false,
            showForgotPassword: false,
            showInstallApp: false,
            isMutedVideo: true,
            showModalsHelpCenter: {
                modalHelpGeneral1: false,
                modalHelpGeneral2: false,
                modalHelpGeneral3: false,
                modalHelpGeneral4: false,
                modalHelpMembership1: false,
                modalHelpMembership2: false,
                modalHelpMembership3: false,
                modalHelpMembership4: false,
                modalHelpInstall1: false,
                modalHelpProfile1: false,
                modalHelpProfile2: false,
                modalHelpProfile3: false,
                modalHelpProfile4: false,
                modalHelpProfile5: false,
                modalHelpProfile6: false,
                modalHelpServices1: false,
                modalHelpServices2: false,
                modalHelpServices3: false,
            },
            showModileGellary: false,
            promoNameHoliday: '',
        }
    },
    created() {
        console.log('created from App');
        this.$bus.$on('open_modal',this.openModal);
        this.$bus.$on('close_modal',this.closeModal);
        this.$bus.$on('add_no_scroll',this.addNoScroll);
        this.$bus.$on('remove_no_scroll',this.removeNoScroll);
        this.$bus.$on('set_is_login',this.setIsLogin);
        this.$bus.$on('init_echo',this.initEcho);
        this.$bus.$on('show_login_form',this.openLogin);
        this.$bus.$on('update_service_worker',this.serviceWorkerRegister)
        this.$bus.$on('open_register',this.openRegister)
        this.$bus.$on('handle_resize',this.handleResize)

        this.myUrl = window.myUrl ;
        this.cdnUrl = window.cdnUrl ;
        this.version = window.version ;
        //console.log(this.version,'0.0.119 test 5' );
        console.log(BUILD_TIMESTAMP);
        this.getTimeMaintenance()
    },
    watch: {
        $route(to, from) {
            console.log('watch route from:', from.fullPath);
            console.log('watch route to:', to.fullPath);
            if(this.$mq !== 'desktop' && this.$route.name !== 'profile'){
                const userAgent = window.navigator.userAgent
                if(userAgent && userAgent.includes('iPhone')){
                    this.$nextTick(()=>{
                        console.log('go scrollBy')
                        window.scrollTo(0, window.scrollY + 1);
                        // setTimeout(() => {
                        //     console.log('go scrollBy')
                        //     window.scrollTo(0, window.scrollY + 1);
                        //     // window.scrollTo(0, window.scrollY - 1);
                        // },300);
                    })
                }
            }
        }
    },
    mounted() {
        this.handleResize();

        console.log('APP mounted.', this.$mq);
        console.log('route: ' + this.$router.currentRoute.name);
        console.log(BUILD_TIMESTAMP);
        // регистрируем сервис воркера
        if (window.environment !== 'local'){
            console.log('before this.serviceWorkerRegister()')
            this.serviceWorkerRegister();
        }

        classList = this.getClassList();
        this.checkSupportedWebp().then(() => {
            console.log('checkSupportedWebp')
        });
        // only landing
        if(this.$router.currentRoute.name === 'landing' || this.$router.currentRoute.path.includes('promo') || this.$router.currentRoute.path.includes('welcome') || this.$router.currentRoute.path.includes('appearance')){
            console.log('route landing');
            this.observerAnime();
            this.animatesHeader();
            this.setBtnTopObserver();
            if(window.nameHoliday){
                console.log('nameHoliday ' + window.nameHoliday);
                if(window.nameHoliday === 'freechat'){
                    setTimeout(()=>{
                        this.showModalHoliday(window.nameHoliday);
                    },5000);
                }

            }

        }
        if(this.$router.currentRoute.name === 'about'){
            console.log('route about');
            this.observerAnime();
        }
        if(this.$router.currentRoute.name === 'appearance'){
            console.log('route appearance');
            this.observerAnime();
        }
        const listRoute = [
            'gallery',
            'home',
            'auth',
            'profile',
            'account',
            'SuccessRegistration',
            'PaymentCgStatus',
            'PaymentCgStatusTest',
            'PaymentPage',
            'UpdateMyStory',
            'CreateMyStory',
            'SupportChat',
            'chat',
        ]
        console.log(this.$router.currentRoute.name);
        if (!listRoute.find(item => item === this.$router.currentRoute.name)) {
            this.hideGlobalLoader();
            this.getCsrfToken();
        }

        window.addEventListener('resize', this.handleResize);
        // if (this.$router.currentRoute.name === 'landing' || this.$router.currentRoute.name === 'mainRich' ) {
            // this.animateLandingElements()
        //     this.startProgressOnScroll()
        //     this.highlightLinkOnScroll()
        //     this.stickyLandingHeader()
        // }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
        this.$bus.$off('open_modal',this.openModal);
        this.$bus.$off('close_modal',this.closeModal);
        this.$bus.$off('add_no_scroll',this.addNoScroll);
        this.$bus.$off('remove_no_scroll',this.removeNoScroll);
        this.$bus.$off('set_is_login',this.setIsLogin);
        this.$bus.$off('init_echo',this.initEcho);
        this.$bus.$off('show_login_form',this.openLogin);
        this.$bus.$off('update_service_worker',this.serviceWorkerRegister)
        this.$bus.$off('open_register',this.openRegister)
        this.$bus.$off('handle_resize',this.handleResize)
        // if (this.$router.currentRoute.name === 'landing') {
        //     ScrollTrigger.getAll().map(trigger => trigger ? trigger.disable() : '');
        // }
    },
    methods: {
        getTimeMaintenance(){
            console.log('getTimeMaintenance');
            axios.get('/get-time-maintenance')
                .then(response => {
                    console.log(response.data)
                    if(response.data.time_maintenance){
                        this.$bus.$emit('set_time_maintenance',response.data.time_maintenance);
                        this.timeMaintenance = response.data.time_maintenance;
                        // if(this.timeMaintenance) this.error500 = true;
                    }
                })
                .catch(e=>{
                    console.log(e)
                    // this.errorHandler(e)
                })
        },
        initEcho(){
            console.log('initEcho()')
            window.Echo = new Echo({
                broadcaster: 'pusher',
                wsHost: process.env.MIX_PUSHER_HOST,
                wsPort: process.env.MIX_PUSHER_PORT,
                wssPort: process.env.MIX_PUSHER_PORT,
                key: process.env.MIX_PUSHER_APP_KEY,
                cluster: process.env.MIX_PUSHER_APP_CLUSTER,
                forceTLS: true,
                disableStats: true,
                enabledTransports: ['ws', 'wss'],
            });
            window.Echo.connector.pusher.connection.strategy.transports.wss.transport.manager.livesLeft = Infinity;

            try {
                window.BeamsClient = new PusherPushNotifications.Client({
                    instanceId: process.env.MIX_BEAMS_INSTANCE_ID,
                });
            }catch (e){
                console.error('create BeamsClient');
            }
        },
        getCsrfToken(){
            axios.get('/new-csrf-token')
                .then(response=>{
                    console.log('/new-csrf-token');
                    console.log(response.data);
                    this.isLogin = response.data.isLogin;
                    this.csrfHandel(response.data.token, response.data.sessionLifetime);
                })
        },
        csrfHandel(token, minutes=10080){
            const metaElem = document.getElementById('csrf_token')
            metaElem.setAttribute('content',token);
            axios.defaults.headers.common['X-CSRF-TOKEN'] = token;
            // let expires = "";
            // if (minutes) {
            //     const date = new Date();
            //     date.setTime(date.getTime() + (minutes * 60 * 1000));
            //     expires = date.toUTCString();
            // }
            // document.cookie = `CSRF-TOKEN=${token}; expires=${expires}; path=/; SameSite=Lax`;
        },
        setIsLogin(status){
            this.isLogin = status
        },
        clickFooter(){
            if(this.$mq === 'desktop'){
                // this.$bus.$emit('close_side_bar');
                this.$bus.$emit('close_nav_menu');
            }
        },
        clickPage(){
            console.log('app clickPage')
            this.$bus.$emit('close_message_drop_menu');
            this.$bus.$emit('close_emoji_letter');
            this.$bus.$emit('close_chat_menu_upload');
            this.$bus.$emit('close_options_menu');
        },
        showModalHC(name) {
            console.log('showModalHC - '+ name)
            this.showModalsHelpCenter[name] = true;
            this.openModal();
        },
        closeModalHoliday(){
            this.promoNameHoliday = '';
        },
        showModalHoliday(nameHoliday){
            this.promoNameHoliday = nameHoliday;
        },
        closeModalHC(name){
            console.log('closeModalHC - '+ name)
            this.showModalsHelpCenter[name] = false;
            this.closeModal()
        },
        clickNavbarMobile(event){
            console.log('clickNavbarMobile');
            const { target } = event;
            const computedStyle = window.getComputedStyle(event.target, ':after');
            const isLink = target?.href?.includes('#');
            if (
                computedStyle.getPropertyValue('content')
                    .replace(/"/g, '') === ' '
            ) {
                this.closeAllLendingModal();
            }
            if (isLink) {
                setTimeout(() => {
                    this.closeAllLendingModal();
                }, 100);
            }
        },
        openMenuMobile(){
            classList.add('no-scroll');
            this.isOpenMenuMobile = true
        },
        openContactUs(){
            classList.add('no-scroll');
            this.showContactUs = true
        },
        setVideoEl(el){
            videoEl = el;
        },
        openLogin(){
            this.closeAllLendingModal();
            classList.add('no-scroll');
            this.isBlurMain = true;
            this.showLogin = true;

        },
        addNoScroll(){
            console.log('addNoScroll()')
            classList.add('no-scroll');
            // if(this.$mq === 'desktop'){
            //
            // }

        },
        removeNoScroll(){
            classList.remove('no-scroll');
            // if(this.$mq === 'desktop'){
            //
            // }

        },
        openModal(){
            classList.add('no-scroll');
            this.isBlurMain = true;
        },
        closeModal(){
            classList.remove('no-scroll');
            this.isBlurMain = false;
        },
        openRegister(){
            this.closeAllLendingModal();
            classList.add('no-scroll');
            this.isBlurMain = true;
            this.showRegister = true;

        },
        openForgotPassword(){
            this.closeAllLendingModal();
            classList.add('no-scroll');
            this.isBlurMain = true;
            this.showForgotPassword = true;
        },
        openInstall(){
            this.closeAllLendingModal();
            classList.add('no-scroll');
            this.isBlurMain = true;
            this.showInstallApp = true;

        },
        closeAllLendingModal(){
            classList.remove('no-scroll');
            this.isBlurMain = false;
            this.showLogin = false;
            this.showRegister = false;
            this.showForgotPassword = false;
            this.showInstallApp = false;
            this.isOpenMenuMobile = false;
            this.showContactUs = false;
        },
        getClassList(){
            console.log('getClassList')
            const { classList} = document.documentElement;
            return classList;
        },
        async checkSupportedWebp(){
            if(!classList) return ;
            setTimeout(() => classList.remove('preload'), 300);
            if (await this.webpIsSupported()) {
                classList.remove('no-webp');
                classList.add('webp');
            } else {
                classList.remove('webp');
                classList.add('no-webp');
            }
        },
        async webpIsSupported() {
            // If the browser doesn't do the method createImageBitmap, you can't display webp format
            // const self = this;
            if (!window.createImageBitmap) return false;

            // Base64 representation of a white point image
            const webpData = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=';

            // Retrieve the Image in Blob Format
            const blob = await fetch(webpData)
                .then((r) => r.blob());

            // If the createImageBitmap method succeeds, return true, otherwise false
            return createImageBitmap(blob)
                .then(
                    () => true,
                    () => false,
                );
        },
        // onLoadPage() {
        //     const htmlEl = document.documentElement;
        //     const {classList} = htmlEl;
        //     setTimeout(() => classList.remove('preload'), 300);
        // },
        animatesHeader(){
            // const header = this.$refs.lending_header;
            const header = document.querySelector('.header');
            const introSection = document.querySelector('.intro');
            const buttonScroll = document.querySelector('.button_scroll');
            const handleIntersection = (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        header.classList.add('header_dark');
                        header.classList.remove('header_light', 'header_shadow');
                    } else {
                        header.classList.add('header_light', 'header_shadow');
                        header.classList.remove('header_dark');
                    }
                });
            };
            let observerHeader = null;
            if (header) {
                observerHeader = new IntersectionObserver(handleIntersection, {
                    rootMargin: `-${header.offsetHeight}px 0px 0px 0px`,
                });
            }
            if (introSection && observerHeader) observerHeader.observe(introSection);
            buttonScroll?.addEventListener('click', () => {
                window.scrollTo({
                    top: introSection.offsetHeight,
                    behavior: 'smooth',
                });
            });

        },
        observerAnime(){
            console.log('observerAnime')
            const observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            entry.target.classList.add('is-animated');
                            observer.unobserve(entry.target);
                        }
                    });
                },
                {
                    root: null,
                    threshold: 0.5,
                },
            );
            document
                .querySelectorAll('.js-animate')
                .forEach((el) => {
                    observer.observe(el);
                });
            // if(this.$refs.section_promo) observer.observe(this.$refs.section_promo)
            // if(this.$refs.section_features) observer.observe(this.$refs.section_features)
        },
        setBtnTopObserver(){
            const btnTop = document.querySelector('.js-top');
            const scrollObserverElement = document.querySelector('#scrollToTopObserver');
            if(!btnTop || !scrollObserverElement) return
            const btnTopObserver = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            btnTop.classList.remove('is-visible');
                        } else {
                            btnTop.classList.add('is-visible');
                        }
                    });
                },
                {
                    root: null,
                    threshold: 0,
                },
            );

            // btn to top on scroll
            btnTopObserver.observe(scrollObserverElement);
            btnTop?.addEventListener('click', () => {
                window.scrollTo(0, 0);
            });
        },
        // end new version

        //OLD version
        handleResize() {
            const userAgent = window.navigator.userAgent
            if( userAgent && userAgent.includes('iPhone')){
                this.windowWidth = document.documentElement.clientWidth || window.innerWidth;
                this.windowHeight = document.documentElement.clientHeight || window.innerHeight;
            }else {
                this.windowWidth = window.innerWidth;
                this.windowHeight = window.innerHeight;
            }

            if(this.$mq !== 'desktop'){
                this.appHeight()
            }
        },
        appHeight(){
            let vh = this.windowHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`)
        },
        closeModalWelcome() {
          this.modalWelcome = false
        },
        openModalWelcome() {
            this.modalWelcome = true
        },
        openMediaStory(media) {
            this.mediaStory[0] = {src: media}
            this.indexMedia = 0
        },
        openVideoStory(media) {
            this.mediaStory[0] = {src : media, autoplay: true}
            this.indexMedia = 0
        },
        gotoAccountWoman(womanID){
            axios.get(`/get-user-for-members`)
                .then(response => {
                    console.log(response.data);
                    if (!response.data.currentUser){
                        this.visitWoman = womanID;
                        this.showLogin = true;
                        // $('#loginModal').modal('show');
                    }else if (response.data.currentUser && response.data.currentUser.role === 'man' ){
                        window.location.href = `${this.myUrl}/profile/${womanID}`;
                    }
                })
        },
        gotoBlog(){
            console.log('GotoBlog');
            window.history.back();
        },
        // onScrollBlog(){
        //     console.log('onScrollBlog');
        //     const blogContainer = document.getElementById('blogContainer');
        //     if(blogContainer){
        //         // const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        //         // console.log(scrollTop,blogContainer.scrollHeight);
        //         console.log($(window).scrollTop(),$(window).height(),$(document).height());
        //
        //         if ($(window).scrollTop() + $(window).height() >= ($(document).height() - 10)){
        //             console.log('onScroll new blog');
        //             this.$bus.$emit('get_new_blogs');
        //         }
        //         if (this.$mq ==='desktop' && ($(window).scrollTop() >= 70)){
        //             console.log('fixed header blog on desktop');
        //             blogContainer.classList.add('is-fixed-header')
        //         } else {
        //             blogContainer.classList.remove('is-fixed-header')
        //         }
        //     }
        //
        // },

        refreshMemberData(){
            console.log('app refreshMemberData');
            this.$bus.$emit('refresh_member_data');
            this.modalConnectError = false;
        },
        noInternet(){
            console.log('noInternet');
            // $('html').removeClass('is-loading');
            this.modalConnectError = true;
        },
        openModalClear() {
            this.modalConnectError = true
            if (this.$mq !== 'desktop') {
                document.documentElement.classList.add('no-scroll')
            }
        },
        closeModalConnectError() {
            this.modalConnectError = false
            if (this.$mq !== 'desktop') {
                document.documentElement.classList.remove('no-scroll')
            }
        },
        handlerContentMenu(e){
            if(this.myUrl === 'https://hablario.com'){
                e.preventDefault();
            }
        },
        // checkUpdateServiceWorker(){
        //     if ('serviceWorker' in navigator) {
        //         // Проверка наличия обновлений и регистрация service worker'а
        //         const checkForUpdates = async () => {
        //             const registration = await navigator.serviceWorker.getRegistration();
        //             if (registration) {
        //                 // Получение информации о текущей и предыдущей версиях кода
        //                 const { current, previous } = registration.waiting;
        //                 if (current && previous) {
        //                     // Сравнение версий кода
        //                     if (current !== previous) {
        //                         console.log('Новая версия кода доступна');
        //                         console.log('need_reload_page');
        //                         this.bus.$emit('need_reload_page');
        //                     }
        //                 }
        //             }
        //         };
        //         // Прослушивание события onupdatefound
        //         navigator.serviceWorker.addEventListener('controllerchange', () => {
        //             // Вызов функции проверки наличия обновлений
        //             checkForUpdates().then(r => console.log('checkForUpdates'));
        //         });
        //     }
        // },
        serviceWorkerRegister() {
            console.log('serviceWorkerRegister')
            if ('serviceWorker' in navigator) {
                if(!timeOutRegisterServiceWorker){
                    timeOutRegisterServiceWorker = true;
                    setTimeout(()=>{
                        timeOutRegisterServiceWorker = false
                    },10000)
                    navigator.serviceWorker.register(`${this.myUrl}/service-worker.js`, {
                        scope: '/'
                    }).then((registration) => {
                        console.log('Registration was successful')

                        registration.update()
                            .then(() => {
                                console.log('registration.update');
                            });
                        console.log('Laravel PWA: ServiceWorker registration successful with scope: ', registration.scope);

                        navigator.serviceWorker.getRegistrations()
                            .then((registrations) => {
                                console.log('getRegistrations count: ' + registrations.length)
                                for (let registration of registrations) {
                                    console.log(registration)
                                    let scope = registration.scope
                                    const testScope = `${this.myUrl.trim()}/`
                                    if( this.myUrl && scope.trim() !== testScope ){
                                        console.log('Start Service Worker unregistered:', registration.scope);
                                        registration.unregister()
                                            .then((success) => {
                                                if (success) {
                                                    console.log('Service Worker unregistered:', registration.scope);
                                                } else {
                                                    console.log('Service Worker could not be unregistered:', registration.scope);
                                                }
                                            }).catch((error)=>{
                                            console.error('Error Service Worker unregistered:', error);
                                        });
                                    }
                                }
                            })
                            .catch((error) => {
                                console.error('Error getting service worker registrations:', error);
                            });



                    }).catch(err => console.log('ServiceWorker registration failed:', err));
                }
            }
        },
        hideGlobalLoader(){
            setTimeout(() => {
                this.isGlobalLoader = false;
                // $("#global-loader").fadeOut("slow")
                //$('html').removeClass('is-loading')
                document.documentElement.classList.remove('is-loading')

            }, 100)
        },
        // navClick($event) {
        //     let link = $event.currentTarget,
        //         hash = link.hash,
        //         offset = this.$refs.header.clientHeight,
        //         vm = this
        //     if (hash) {
        //         gsap.to(window,  1,{
        //             scrollTo:{y: $(hash), offsetY: offset, autoKill: false},
        //             ease: Power4.easeIn,
        //             onComplete() {
        //                 window.location.hash = hash;
        //                 if (vm.$refs.navbar.classList.contains('is-open')) {
        //                     vm.toggleMenu()
        //                 }
        //             }});
        //     }
        // },
        highlightLinkOnScroll() {
            // const observerLink = new IntersectionObserver(entries => {
            //     entries.forEach(entry => {
            //         const entryId = entry.target.getAttribute('id');
            //         if (entryId) {
            //             if (entry.intersectionRatio > 0) {
            //                 document.querySelector(`.navbar li a[href="#${entryId}"]`).classList.add('is-current')
            //             } else {
            //                 document.querySelector(`.navbar li a[href="#${entryId}"]`).classList.remove('is-current')
            //             }
            //         }
            //     });
            // });
            // document.querySelectorAll('section[id]').forEach((section) => {
            //     observerLink.observe(section);
            // });
        },
        startProgressOnScroll() {
            let options = {
                rootMargin: '300px',
                threshold: .5
            }
            const observerSection = new IntersectionObserver(entry => {
                if (entry[0].isIntersecting > 0 && this.progressLoading) {
                    setTimeout(() => this.progressLoading = false, 100)
                }
            }, options);
            let target = document.querySelector('.section_progress');
            if (target){
                observerSection.observe(target);
            }

        },
        toggleMenu() {
            this.$refs.btnMenu.classList.toggle('active')
            this.$refs.navbar.classList.toggle('is-open')
            document.body.classList.toggle("modal-open")
        },
        scrollDown(){
            let offset = this.$mq !== 'desktop' ? window.innerHeight - 10 : window.innerHeight - this.$refs.lending_header.clientHeight + 15;
            console.log(offset)
            window.scrollTo( 0,offset );
            // gsap.to(window,  1,{ scrollTo: {y: offset, autoKill: false }, ease: Power4.easeIn});
        },
        // stickyLandingHeader(){
        //     let offset = this.$mq !== 'desktop' ? window.innerHeight - window.innerHeight / 4 : window.innerHeight - 500
        //     ScrollTrigger.create({
        //         start: `top -${offset}`,
        //         end: 99999,
        //         toggleClass: {className: 'sticky', targets: this.$refs.header}
        //     });
        // },
        // animateLandingElements(){
        //     function animateFrom(elem, direction) {
        //         direction = direction | 1;
        //         let x = 0,
        //             y = direction * 100;
        //         if (elem.classList.contains("gs_reveal_fromLeft")) {
        //             x = -100;
        //             y = 0;
        //         } else if(elem.classList.contains("gs_reveal_fromRight")) {
        //             x = 100;
        //             y = 0;
        //         }
        //         gsap.fromTo(elem, {x: x, y: y, autoAlpha: 0}, {
        //             duration: 1.25,
        //             x: 0,
        //             y: 0,
        //             autoAlpha: 1,
        //             ease: "expo",
        //             overwrite: "auto"
        //         });
        //     }
        //     function hide(elem) {
        //         gsap.set(elem, {autoAlpha: 0});
        //     }
        //     gsap.utils.toArray(".gs_reveal").forEach((elem) => {
        //         hide(elem);
        //         ScrollTrigger.create({
        //             trigger: elem,
        //             onEnter: function() { animateFrom(elem) },
        //             onEnterBack: function() { animateFrom(elem, -1) },
        //             onLeave: function() { hide(elem) }
        //         });
        //     });
        // },
        //данные для работы компонентов будем брать внутри компонента

        registerUser(user){
            console.log('registerUser methods');
            // $("#registerModal ").modal("hide");
            // this.routeHandler(user,'/success-registration');
            window.location.href = this.myUrl+'/success-registration';
        },

        registerInBlog(user){
            console.log('register In Blog');
            const button = document.getElementById('closeRegister');
            if (button){
                button.click();
            }
            this.$bus.$emit('login_user',user);
            // $("#registerModal ").modal("hide");
        },

        loginUser(user){
            console.log('loginUser methods')
            // $("#loginModal ").modal("hide");
            this.currentUser = user ;
            let path = '/gallery'
            if (this.visitWoman){
                path = `/profile/${this.visitWoman}`
            }
            this.routeHandler(user,path);
        },
        loginInBlog(user){
            console.log('loginInBlog')
            const button = document.getElementById('closeLogin');
            if (button){
                button.click();
            }
            this.$bus.$emit('login_user',user);
            // $("#loginModal ").modal("hide");
        },

        routeHandler(user,path){
            console.log('routeHandler');
            console.log(user);
            this.currentUser = user ;
            switch (user.role){
                // case null:
                //     window.location.href = this.myUrl+'/success-registration';
                //     break;
                case "woman":
                case "man":
                    window.location.href = this.myUrl+path;
                    break;
                case "admin_operator":
                case "operator":
                    window.location.href = this.myUrl+'/operator';
                    break;
            }
        },
        errorHandler(err){
            // console.log(err);
            if (err.response) {
                // client received an error response (5xx, 4xx)
                console.log('err.response');
                console.log(err.response);
                if (err.response.status === 419 || err.response.status === 401){
                    console.log('err.response status 419');
                    alert('The session timed out, you need to reload this page');
                    throw new Error(`Error response status ${err.response.status}`);
                    window.location.href = this.myUrl + '/';
                }else {
                    throw new Error(`Error response status ${err.response.status}`);
                }
            } else if (err.request) {
                console.log('err.request!!!');
                console.log(err.request);
                alert('Check your internet connection and try again');
            }  else {
                console.log('anything else ');
                   console.error(err);

            }
        },
    }
});



