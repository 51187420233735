<template>
    <!--    class="sidebar is-fixed" :class="{'is-open':isNavOpen}"-->
    <div :class="mainClass" ref="sidebar">
        <template v-if="$mq==='desktop'">
            <nav class="nav sidebar__nav">
                <div class="nav__header sidebar__nav-button button button_bordered" @click="isOpenMenu = !isOpenMenu">
                    <span>
                        <span class="nav__title">Menu</span>
                        <span class="badge badge_warning badge_animate" v-if="showBadgeWarning"></span>
                        <button class="button button_menu">
                            <span></span>
                        </button>
                    </span>
                </div>
                <nav-menu
                    :my-url="myUrl"
                    :settings-init="settingsInit"
                    :member-id="chatOwner.id"
                    :current-user="currentUser"
                    :support-unread="supportContact ? supportContact.unread : 0"
                    :disabled-notification="disabledNotification"
                    :on-subscribe-notification="onSubscribeNotification"
                    :show-notification-button="showNotificationButton"
                    :show-badge-warning="showBadgeWarning"
                    :is-sound="isSound"
                    @close_menu="isOpenMenu=false"
                    @open_support_chat="openSupportChat"
                    @open_search="openSearch"
                    @open_price="openPrice"
                    @errorhandler="errorHandler"
                    v-if="isOpenMenu"
                ></nav-menu>
            </nav>

            <div class="sidebar__container" ref="sidebarContainer"
                 @mousedown="resizeOn"
                 @mouseup="resizeOff"
                 @mousemove.prevent="resizePanel">

                <div class="sidebar__actions">
                    <button class="sidebar__button button button_primary" @click="changeNavOpen">
                    <span>
                        <span class="button__text"><span>NEW</span> message</span>
                        <span class="button__icon">
                            <span class="tooltip tooltip_abs tooltip_primary">
                                <span class="tooltip__content" v-if="countUnread">{{ textCountUnread }}</span>
                            </span>
                            <i class="icon icon_chat-bubble-bordered"></i>
                        </span>
                    </span>
                    </button>
                    <button class="sidebar__button button button_bordered" @click.prevent="openPaymentPage">
                    <span>
                        <span class="button__text">Buy Credits</span>
                        <i class="button__icon icon icon_money"></i>
                    </span>
                    </button>
                    <button class="sidebar__button button button_bordered" @click.prevent="toggleSupport">
                    <span>
                        <span class="button__text">Support</span>
                        <span class="button__icon">
                            <span class="tooltip tooltip_abs tooltip_primary">
                                <span class="tooltip__content"
                                      v-if="supportContact && supportContact.unread > 0">
                                    {{ supportContact.unread }}
                                </span>
                            </span>
                            <i class="button__icon icon icon_headset"></i>
                        </span>
                    </span>
                    </button>

<!--                  sidebar promo banner start-->
                    <div class="sidebar__promo" @click.prevent="openPaymentPage" ref="sidebarPromo"
                         v-show="showSidebarPromo || !isSeeFooter">
                        <transition name="fade" mode="out-in">
                            <div class="sidebar__promo-container"
                                 :key="currentPromoIndex">
<!--                                <template v-if="promos[currentPromoIndex].type === 'buyCredits'">-->
<!--                                    <picture>-->
<!--                                        <source type="image/webp" :srcset="`${myUrl}/source/images/banner/banner-freechat_old.webp`">-->
<!--                                        <img class="sidebar__promo-banner" :src="`${myUrl}/source/images/banner/banner-freechat_old.jpg`" alt="gallery-three" width="185"-->
<!--                                             height="574">-->
<!--                                    </picture>-->
<!--                                </template>-->
<!--                                <template v-else-if="promos[currentPromoIndex].type === 'photoDay'">-->
<!--                                    <img class="sidebar__promo-banner"-->
<!--                                         :src="`${myUrl}/source/images/holidays/photo/banner-vertical.png`"-->
<!--                                         alt="Banner photo day">-->
<!--                                </template>-->

                                <template v-if="holidayPromotionName === 'freechat'">
                                    <picture>
                                        <source type="image/webp" :srcset="`${myUrl}/source/images/banner/freechat/banner-freechat.webp`">
                                        <img class="sidebar__promo-banner" :src="`${myUrl}/source/images/banner/freechat/banner-freechat.jpg`" alt="gallery-three" width="185"
                                             height="574">
                                    </picture>
                                </template>

                            </div>
                        </transition>
                    </div>
<!--                    sidebar promo banner end-->
                </div>


                <div class="panel panel_contacts" ref="sidebarContacts">
                    <ul class="tabs panel__tabs">
                        <li class="tabs__tab"><span class="tabs__link" :class="{'is-active':typeTab==='history'}"
                                                    @click="selectTabContact('history')">
                            <i class="icon icon_history"></i>History</span>
                        </li>
                        <li class="tabs__tab"><span class="tabs__link" :class="{'is-active':typeTab==='favorites'}"
                                                    @click="selectTabContact('favorites')">
                            <i class="icon icon_favorite"></i>Favorites</span>
                        </li>
                    </ul>
                    <div class="panel__content" ref="panelListContacts">
                        <contact-favorite
                            :s3-url="s3Url"
                            :current-user="currentUser"
                            :my-url="myUrl"
                            :contacts="contacts"
                            :role-opponent="roleOpponent"
                            :ban-list="banList"
                            :chat-owner="chatOwner"
                            :is-all-favorite="isAllFavorite"
                            :count-contacts="countContacts"
                            :is-supported-webp="isSupportedWebp"
                            @errorhandler="errorHandler"
                            v-if="showContacts && typeTab==='favorites'"
                        ></contact-favorite>
                        <contact-history
                            :s3-url="s3Url"
                            :current-user="currentUser"
                            :my-url="myUrl"
                            :contacts="contacts"
                            :role-opponent="roleOpponent"
                            :ban-list="banList"
                            :chat-owner="chatOwner"
                            :is-all-history="isAllHistory"
                            :count-contacts="countContacts"
                            :is-supported-webp="isSupportedWebp"
                            @errorhandler="errorHandler"
                            v-if="showContacts && typeTab==='history'"
                        ></contact-history>
                    </div>
                </div>
                <div class="panel__handler" ref="handler"></div>
                <div class="panel panel_chat" ref="sidebarChat">
                    <div class="panel__header">
                        <span class="panel__title"><i class="icon icon_plus"></i><span>New chat</span></span>
                    </div>
                    <div class="panel__content">
                        <contact-unread
                            :s3-url="s3Url"
                            :current-user="currentUser"
                            :chat-owner="chatOwner"
                            :my-url="myUrl"
                            :contacts="contacts"
                            :role-opponent="roleOpponent"
                            :ban-list="banList"
                            :unread="countUnread"
                            :is-new-version="isNewVersion"
                            :is-all-chat-request="isAllChatRequest"
                            :count-contacts="countContacts"
                            :is-supported-webp="isSupportedWebp"
                            @errorhandler="errorHandler"
                            v-if="showContacts"
                        ></contact-unread>
                    </div>
                </div>
            </div>

        </template>
        <template v-else>
            <div class="header__container">

                <div class="sidebar sidebar_mobile">

                    <div class="sidebar__actions">
                        <base-logo></base-logo>
                        <button class="sidebar__button button button_icon" @click="gotoMobileContacts">
                            <span class="button__icon">
                                <span class="tooltip tooltip_abs tooltip_primary">
                                    <span class="tooltip__content" v-if="countUnread">{{ textCountUnread }}</span>
                                </span>
                                <i class="icon icon_chat-bubble-gradient"></i>
                            </span>
                        </button>
                    </div>
                    <div class="sidebar__nav-button" @click.prevent="openMobileMenu">
                        <span class="badge badge_warning badge_animate" v-if="showBadgeWarning"></span>
                        <button class="button button_menu">
                            <span class="tooltip tooltip_abs tooltip_primary">
                                <span class="tooltip__content"
                                      v-if="supportContact && supportContact.unread">{{ supportContact.unread }}</span>
                            </span>
                            <span></span>
                        </button>
                    </div>
                    <nav class="nav sidebar__nav" v-if="isOpenMobileMenu">
                        <nav-menu
                            :my-url="myUrl"
                            :settings-init="settingsInit"
                            :member-id="chatOwner.id"
                            :current-user="currentUser"
                            :support-unread="supportContact ? supportContact.unread : 0"
                            :disabled-notification="disabledNotification"
                            :on-subscribe-notification="onSubscribeNotification"
                            :show-notification-button="showNotificationButton"
                            :show-badge-warning="showBadgeWarning"
                            :is-sound="isSound"
                            @close_menu="isOpenMobileMenu = false"
                            @open_support_chat="openSupportChat"
                            @open_search="openSearch"
                            @open_price="openPrice"
                            @errorhandler="errorHandler"
                        ></nav-menu>

                    </nav>

                    <mobile-contacts v-if="isOpenMobileContacts"
                                     :s3-url="s3Url"
                                     :current-user="currentUser"
                                     :my-url="myUrl"
                                     :contacts="contacts"
                                     :role-opponent="roleOpponent"
                                     :ban-list="banList"
                                     :chat-owner="chatOwner"
                                     :is-all-favorite="isAllFavorite"
                                     :count-contacts="countContacts"
                                     :is-supported-webp="isSupportedWebp"
                                     :count-unread="countUnread"
                                     :is-all-chat-request="isAllChatRequest"
                                     :is-all-history="isAllHistory"
                                     :is-new-version="isNewVersion"
                                     :show-contacts="showContacts"
                                     @button_back_mobile="isOpenMobileContacts=false"
                                     @errorhandler="errorHandler"
                    ></mobile-contacts>
                </div>
            </div>
        </template>


    </div>
</template>
<script>


import ChatRequest from "../modules/ChatRequest.vue";
import ChatFavorite from "../modules/ChatFavorite.vue";
import ChatHistory from "../modules/ChatHistory.vue";
import NavMenu from "./NavMenu.vue";
import ContactFavorite from "./ContactFavorite.vue";
import ContactHistory from "./ContactHistory.vue";
import ContactUnread from "./ContactUnread.vue";
import BaseLogo from "./BaseLogo.vue";
import MobileContacts from "./MobileContacts.vue";
import ClientSupportChat from "../ClientSupportChat.vue";
import SupportChat from "./modals/SupportChat.vue";

let footerObserver = null;
let footer = null;
let intervalSwitchPromo = null;

export default {
    name: "SideBar",
    components: {
        SupportChat,
        ClientSupportChat,
        MobileContacts,
        BaseLogo,
        ContactUnread,
        ContactHistory,
        ContactFavorite,
        NavMenu,
        ChatHistory,
        ChatFavorite,
        ChatRequest
    },
    props: [
        'currentUser',
        'chatOwner',
        'myUrl',
        's3Url',
        'type',
        'contactsInit',
        'selectedContact',
        'isAllHistory',
        'isAllFavorite',
        'isAllChatRequest',
        'isDownload',
        'banList',
        'roleOpponent',
        'indicatorNewOpponent',
        'isSearchDone',
        'settingsInit',
        // 'supportMessages',
        'supportContact',
        // 'lastSupportMessagesID',
        // 'isAllSupportMessages',
        // 'supportTyping',
        'showRequest',
        'showMobFavorite',
        'showMobHistory',
        'showSupport',
        'windowHeight',
        'countUnread',
        'showNotificationButton',
        'onSubscribeNotification',
        'disabledNotification',
        'isNewVersion',
        'countContacts',
        'isSupportedWebp',
        'holidayPromotionName',
        'isSound',
    ],
    // 'indicatorNewMan',
    // 'indicatorNewWoman',
    data() {
        return {
            // showSupport: false,
            opponent: null,
            menuColors: false,
            posReq: null,
            isHandlerDragging: false,
            backBtnContent: 'New message',
            showMobSettings: false,
            showContacts: false,
            isOpenMenu: false,
            isOpenMobileMenu: false,
            isNavOpen: false,
            isOpenMobileContacts: false,
            typeTab: 'favorites',
            isSeeFooter: false,
            showSidebarPromo: false,
            currentPromoIndex: 0,
            promos: [
                {
                    type: 'buyCredits',
                },
                {
                    type: 'photoDay',
                }
            ]
        }
    },
    computed: {
        showBadgeWarning(){
            return !this.chatOwner.country_id || !this.chatOwner.birthday
        },
        mainClass() {
            if (this.$mq !== 'desktop') return `header header_mobile header_fixed${this.isOpenMenu ? ' is-nav-open' : ''}`;

            let classMain = 'sidebar';

            if (this.isSeeFooter) classMain += ' is-absolute'
            else classMain += ' is-fixed';

            if (this.isNavOpen) classMain += ' is-open'
            else if (this.isOpenMenu) classMain += ' is-nav-open';


            return classMain;
            //return (`sidebar is-fixed ${this.isNavOpen ? 'is-open': (this.isOpenMenu ? 'is-nav-open' : '')}`);
        },
        textCountUnread() {
            let countUnread = this.countUnread > 0 ? String(this.countUnread) : '';
            if (this.countUnread > 999) {
                countUnread = `${Math.trunc(this.countUnread / 1000)}K`
            }
            return countUnread
        },
        contacts() {
            if (this.roleOpponent === 'man') {
                return this.contactsInit.filter(contact => {
                    return contact.man && !contact.man.lock;
                })
            } else {
                return this.contactsInit.filter(contact => {
                    return contact.woman && !contact.woman.lock && contact.woman.valid;
                })
            }
        },
    },
    watch: {
        isNavOpen(newValue, oldValue) {
            if (!newValue && this.$mq === 'desktop') {
                if (this.$refs.sidebarContacts) this.$refs.sidebarContacts.style = '';
                if (this.$refs.sidebarChat) this.$refs.sidebarChat.style = '';
            }
        },
        holidayPromotionName(newValue, oldValue) {
            if (!newValue && this.$mq === 'desktop') {
                this.currentPromoIndex = 0;
                // this.stopSwitchPromo();
            }
        }

        // isOpenMobileContacts(newValue, oldValue){
        //     if(newValue) this.$bus.$emit('add_no_scroll');
        //     else this.$bus.$emit('remove_no_scroll');
        // },
        // isOpenMobileMenu(newValue, oldValue){
        //     if(newValue) this.$bus.$emit('add_no_scroll');
        //     else this.$bus.$emit('remove_no_scroll');
        // },
    },
    created() {

        // document.addEventListener('click', this.handleOutsideClick);
        // document.addEventListener('touchstart', this.handleOutsideClick);
    },
    mounted() {
        //????
        if (this.$mq === 'desktop') {
            this.setFooterObserver()
            this.$bus.$on('favorite_scroll_up', this.favoriteScrollUp);
            if (this.holidayPromotionName) {
                this.currentPromoIndex = 1
                // this.startSwitchPromo();
            }
            this.showSidebarPromo = true;
        }

        setTimeout(() => {
            this.showContacts = true
        }, 1000)
        // this.initSettings();
        this.$bus.$on('open_support_chat', this.openSupportChat);
        this.$bus.$on('close_mobile_contacts', this.closeMobileContacts);
        this.$bus.$on('close_nav_menu', this.closeNavMenu);
        this.$bus.$on('close_side_bar', this.closeSideBar);
        this.$bus.$on('close_side_bar', this.closeSideBar);
        this.$bus.$on('favorite_scroll_up', this.favoriteScrollUp);
        this.$bus.$on('start_switch_promo', this.startSwitchPromo);
        this.$bus.$on('stop_switch_promo', this.stopSwitchPromo);
    },
    beforeDestroy() {
        //???
        // document.removeEventListener('click', this.handleOutsideClick);
        // document.removeEventListener('touchstart', this.handleOutsideClick);
        this.$bus.$off('open_support_chat', this.openSupportChat);
        this.$bus.$off('close_mobile_contacts', this.closeMobileContacts);
        this.$bus.$off('close_nav_menu', this.closeNavMenu);
        this.$bus.$off('close_side_bar', this.closeSideBar);
        this.$bus.$off('favorite_scroll_up', this.favoriteScrollUp);
        this.$bus.$off('stop_switch_promo', this.stopSwitchPromo);
        if (this.$refs.sidebarPromo) {
            clearInterval(intervalSwitchPromo);
        }
    },
    methods: {
        changeNavOpen(){
            if(this.isPromoPage())return
            this.isNavOpen=!this.isNavOpen
        },
        stopSwitchPromo() {
            this.currentPromoIndex = 0;
            clearInterval(intervalSwitchPromo);
        },
        startSwitchPromo() {
            if (this.$refs.sidebarPromo) {
                intervalSwitchPromo = setInterval(() => {
                    this.switchPromo();
                }, 5000);
            }
        },
        switchPromo() {
            this.currentPromoIndex = (this.currentPromoIndex + 1) % this.promos.length;
        },
        favoriteScrollUp() {
            console.log('favoriteScrollUp')
            if (this.typeTab !== 'favorites') return;
            if (this.$refs.panelListContacts) this.$refs.panelListContacts.scrollTop = 0
        },
        closeSideBar() {
            this.isNavOpen = false;
            this.isOpenMenu = false;
        },
        closeNavMenu() {
            this.isOpenMenu = false;
            this.isOpenMobileMenu = false;

        },
        setFooterObserver() {
            if (this.$mq !== 'desktop') return;
            console.log('setFooterObserver sidebar');
            const windowHeight = window.innerHeight || document.documentElement.clientHeight;
            const sidebar = this.$refs.sidebar;

            const sidebarContainer = this.$refs.sidebarContainer;
            const mainPage = document.getElementById('mainPage');
            if (!sidebar || !sidebarContainer) return;

            footerObserver = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            if (mainPage && windowHeight > mainPage.offsetHeight) {
                                sidebar.style.top = '20px';
                            //     sidebarContainer.style.height = `${ mainPage.offsetHeight - 20}px`;
                            } else {
                                sidebar.style = '';
                                sidebarContainer.style.height = `${windowHeight}px`;
                            }
                            // sidebar.style.top = '20px';
                            // sidebarContainer.style.height = `${windowHeight}px`;
                            this.isSeeFooter = true;
                            // sidebar?.classList.remove('is-fixed');
                            // sidebar?.classList.add('is-absolute');
                            this.checkSidebarPromo();
                        } else {
                            sidebarContainer.style = '';
                            this.isSeeFooter = false;
                            this.checkSidebarPromo();
                            // sidebar?.classList.remove('is-absolute');
                            // sidebar?.classList.add('is-fixed');
                        }
                    });
                },
                {
                    root: null,
                    rootMargin: '0px',
                    threshold: 0.0,
                },
            );
            footer = document.getElementById('footer')
            if (footer) footerObserver.observe(footer);
        },
        checkSidebarPromo() {
            const sidebarPromo = this.$refs.sidebarPromo
            if (sidebarPromo) {

                const rectFooter = footer.getBoundingClientRect();
                const rectSidebarPromo = sidebarPromo.getBoundingClientRect();
                this.showSidebarPromo = (
                    rectFooter.right < rectSidebarPromo.left ||
                    rectFooter.left > rectSidebarPromo.right ||
                    rectFooter.bottom < rectSidebarPromo.top ||
                    rectFooter.top > rectSidebarPromo.bottom
                );
            }
        },
        gotoMobileContacts() {
            if(this.isPromoPage())return
            this.isOpenMobileContacts = !this.isOpenMobileContacts
            // if(this.isOpenMobileContacts && this.$route.name !== 'MobileContacts' && this.$mq==='desktop'){
            //     setTimeout(()=>{
            //         this.$router.push({name: 'MobileContacts'});
            //     },10)
            // }
            // if(this.$mq==='desktop') return;
        },
        openMobileMenu() {
            console.log('openMenu');
            this.isOpenMobileContacts = false;
            this.isOpenMobileMenu = !this.isOpenMobileMenu;
        },
        closeMobileContacts() {
            console.log('closeMobileMenu');
            this.isOpenMobileContacts = false;
            this.isOpenMobileMenu = false;
        },
        selectTabContact(type) {
            console.log('selectTabContact: ' + type);
            this.typeTab = type;
        },
        // handleOutsideClick(event) {
        //     if(!(this.$refs.btnSettings && this.$refs.menuSettings)) return;
        //     if (this.$mq !=='desktop') {
        //         if (!(this.$refs.btnSettings == event.target
        //             || this.$refs.btnSettings.contains(event.target)
        //             || this.$refs.menuSettings == event.target
        //             || this.$refs.menuSettings.contains(event.target))
        //         ) {
        //             this.showMobSettings = false
        //         }
        //     } else {
        //         if (!(this.$refs.btnSettings == event.target
        //             || this.$refs.btnSettings.contains(event.target)
        //             || this.$refs.menuSettings == event.target
        //             || this.$refs.menuSettings.contains(event.target))
        //         ) {
        //             this.hideMenuColors()
        //         }
        //     }
        //
        // },
        // openModalClear() {
        //     this.$emit('open-modal-clear');
        // },
        goToFavorite() {
            this.$emit('go_to_favorite');
        },
        goToHistory() {
            this.$emit('go_to_history');
        },
        goToRequest(arg) {
            this.$emit('go_to_request', arg);
        },
        closeHistory() {
            this.$emit('close_history');
        },
        closeFavorite() {
            this.$emit('close_favorite');
        },
        closeRequest() {
            this.$emit('close_request');
        },
        // openChatMedia(data){
        //     this.$emit('open_chat_media', data);
        // },
        toggleMobSettings() {
            this.showMobSettings = !this.showMobSettings
            if (this.menuColors) this.menuColors = false
        },
        resetSearch() {
            this.$emit('reset-search')
        },
        toggleSupport() {
            if(this.isPromoPage())return
            // this.showSupport = !this.showSupport
            if (this.$mq !== 'desktop') return
            if (!this.showSupport) {
                this.$bus.$emit('get_support_messages');
                //TODO check
                // if (this.$mq !== 'desktop') document.documentElement.classList.add('no-scroll')
            } else {
                this.$bus.$emit('close_support_chat');
                //TODO check
                // if (this.$mq !== 'desktop') document.documentElement.classList.remove('no-scroll')
            }
        },
        openSupportChat(message = '') {
            // this.showSupport = true;

            if (this.$mq !== 'desktop') {
                this.isOpenMobileMenu = false;
                this.$router.push({name: 'SupportChat'})
            } else {
                this.$bus.$emit('get_support_messages');
            }
            if (message) this.$bus.$emit('set_support_message', message);
        },
        // closeSupport() {
        //     // this.showSupport = false;
        //     this.$bus.$emit('close_support_chat');
        // },
        resizeOff() {
            this.isHandlerDragging = false
        },
        resizeOn(e) {
            if (e.target === this.$refs.handler) {
                this.isHandlerDragging = true;
            }
        },
        resizePanel(e) {
            if (!this.isHandlerDragging) {
                return false;
            }
            // Get offset
            let containerOffsetTop = this.$refs.sidebar.offsetTop,
                panel1 = this.$refs.sidebarContacts,
                panel2 = this.$refs.sidebarChat,
                pointerRelativeXpos = e.clientY - containerOffsetTop,
                pointerRelativeXpos2 = e.clientY - e.target.offsetTop + e.target.offsetHeight;
            let boxAminWidth = 200;
            if (pointerRelativeXpos < window.innerHeight - 60) {
                panel1.style.height = (Math.max(boxAminWidth, pointerRelativeXpos - 2)) + 'px';
                // panel2.style.height = (Math.max(boxAminWidth, pointerRelativeXpos2 - 8)) + 'px';
                panel2.style.height = `calc(100% - ${panel1.style.height})`
                this.calcPosOfBox()
            } else {
                return false;
            }
        },

        calcPosOfBox() {
            if (this.$refs.request) {
                let calc = +this.$refs.request.getBoundingClientRect().top.toFixed()
                setTimeout(() => this.posReq = calc, 0)
            }
        },
        openMenu(arg) {
            this.$emit('open_menu', arg);
        },
        openSearch() {
            this.isOpenMenu = false;
            this.isOpenMobileMenu = false;
            this.$emit('open_search');
        },
        // selectContact(contact){
        //     this.$emit('selectcontact',contact);
        // },
        openPrice() {
            this.$emit('open_price');
        },

        showMenuColors() {
            this.menuColors = true
        },
        hideMenuColors() {
            this.menuColors = false
        },
        openSidebar() {
            this.$refs.sidebar.classList.toggle('is-open')
        },
        openPaymentPage() {
            if(this.isPromoPage())return
            console.log('openPaymentPage');
            if (this.$route.name !== 'PaymentPage') this.$router.push({name: 'PaymentPage'});
            // this.$emit('open_price');
        },
        // changeColorTheme(color) {
        //     document.body.classList.forEach(item=>{
        //         if(item.startsWith('color')) {
        //             document.body.classList.remove(item) ;
        //         }
        //     })
        //     document.body.classList.add(color);
        //
        // },
        errorHandler(e) {
            this.$emit('errorhandler', e);
        },
        isPromoPage(){
            if(this.$route.name === 'promoProfile' || this.$route.name === 'promoChat'){
                this.$bus.$emit('open_register')
                return true
            }else {return false}
        }
    }
}
</script>
<!--
<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity 1s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}
</style>
-->
