<template>
    <div class="page page_fixed page_payment" :class="{'page_mobile':$mq!=='desktop'}">


        <template v-if="$mq==='desktop'">
            <div class="page__header page__header_payment">
                <base-logo></base-logo>
                <span class="page__close" role="button" aria-label="Close" @click="goBack">
                <i class="icon icon_close"></i>
            </span>
            </div>
            <div class="page__container">
                <div class="container">
                    <h3 class="page__heading">Choose your plan</h3>

<!--                    <template v-if="paymentTariffs && paymentTariffs.length===1">-->
<!--                        <div class="page__column">-->
<!--                            <ul class="price price_individual">-->
<!--                                <li v-for="tariff in paymentTariffs" :key="tariff.id" @click.prevent="select(tariff)"-->
<!--                                    class="price__item price__item_individual">-->
<!--                                    <div class="price__header">-->
<!--                                        <i class="price__icon icon icon_heart-white"></i>-->
<!--                                        <h3 class="price__title">{{ tariff.title }}</h3>-->
<!--                                    </div>-->
<!--                                    <div class="price__body">-->
<!--                                        <div class="price__description">-->
<!--                                            <span class="price__value"><span>$</span>{{ Number(tariff.amount).toFixed(2) }}</span>-->
<!--                                            <span class="price__credit">{{ tariff.credits }} credits</span>-->
<!--                                        </div>-->
<!--                                        <price-features-list-->
<!--                                            :tariff="tariff"-->
<!--                                            :bonuses="getBonus(tariff.amount, tariff.bonus)"-->
<!--                                        ></price-features-list>-->
<!--                                        <button class="button button_sm button_primary price__button">-->
<!--                                            <span>Select</span></button>-->
<!--                                    </div>-->
<!--                                </li>-->
<!--                            </ul>-->
<!--                            <section-payment-page :init-show-list="true"></section-payment-page>-->
<!--                        </div>-->
<!--                    </template>-->

                    <template v-if="paymentTariffs && paymentTariffs.length===1">

                        <countdown-promo
                            :deadline="holidayPromotion.stop"
                            @stop_promo="stopPromo"
                            v-if="holidayPromotion && holidayPromotion.stop"
                        ></countdown-promo>

                        <div class="page__column">
                            <ul class="price price_starting">
                                <li v-for="tariff in paymentTariffs" :key="tariff.id" @click.prevent="select(tariff)"
                                    class="price__item price__item_base price__item_starting">
                                    <div class="price__header">
                                        <i class="price__icon icon icon_heart-white"></i>
                                        <h3 class="price__title">{{ tariff.title }}</h3>
                                    </div>
                                    <div class="price__body">
                                        <div class="price__description">
                                            <span class="price__value"><span>$</span>{{ Number(tariff.amount/100).toFixed(2) }}</span>
                                            <span class="price__credit">{{(Number(tariff.credits) + Number(getBonus(tariff.amount, tariff.bonus))).toFixed(0)}} credits</span>
                                        </div>
                                        <price-features-list
                                            :tariff="tariff"
                                            :bonuses="getBonus(tariff.amount, tariff.bonus)"
                                        ></price-features-list>

                                        <button class="button button_sm button_primary price__button">
                                            <span>Select</span></button>
                                    </div>
                                </li>
                            </ul>
                            <section-payment-page :init-show-list="true"></section-payment-page>
                        </div>
                    </template>

                    <template v-else>
                        <ul class="price">
                            <li class="price__item" v-for="tariff in paymentTariffs" :key="tariff.id"
                                @click.prevent="select(tariff)"
                                :class="`price__item_${tariff.title.toLowerCase()}`"
                            >
                                <div class="price__header">
                                    <!--TODO need ask Olga, what is it?-->
                                    <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                                        <use
                                            :xlink:href="`${myUrl}/source/images/icons/icons.svg#${svgIcon[tariff.title]}`"/>
                                    </svg>
                                    <!--end-->
                                    <h3 class="price__title">{{ tariff.title }}</h3>
                                </div>
                                <div class="price__body">
                                    <div class="price__description"><span
                                        class="price__value"><span>$</span>{{ Number(tariff.amount/100).toFixed(2) }}</span>
                                        <span class="price__credit">{{(Number(tariff.credits) + Number(getBonus(tariff.amount, tariff.bonus))).toFixed(0)}} credits</span>
                                    </div>
                                    <price-features-list
                                        :tariff="tariff"
                                        :bonuses="getBonus(tariff.amount, tariff.bonus)"
                                    ></price-features-list>
                                    <button class="button button_sm button_primary price__button">
                                        <span>Select</span></button>
                                </div>
                            </li>
                        </ul>
                        <section-payment-page :init-show-list="false"></section-payment-page>
                    </template>
                </div>
                <div class="page__footer">
                    <div class="page__section">
                        <span><i class="icon icon_secured"></i>Transactions are encrypted and secured</span>
                        <span><i class="icon icon_warning"></i>Before buying credits, please read</span>
                        <a class="page__link" :href="`${myUrl}/terms-and-conditions`"
                           target="_blank">Terms&Conditions</a>
                        <a class="page__link" :href="`${myUrl}/cookies-policy`" target="_blank">Cookies Policy</a>

                        <span>of Hablario</span>

                    </div>
                    <ul class="list list_payment">
                        <li class="list__item">
                            <img :src="`${myUrl}/source/images/payment/pci.svg`" alt="">
                        </li>
                        <li class="list__item">
                            <img :src="`${myUrl}/source/images/payment/visa-secure.svg`" alt="">
                        </li>
                        <li class="list__item">
                            <img class="is-light" :src="`${myUrl}/source/images/payment/master-id.svg`" alt="">
                            <img class="is-dark" :src="`${myUrl}/source/images/payment/dark/master-id.svg`" alt="">
                        </li>
                        <li class="list__item">
                            <img :src="`${myUrl}/source/images/payment/jcb-secure.svg`" alt="">
                        </li>

                        <!--                        2 new payment methods-->
                        <li class="list__item">
                            <img :src="`${myUrl}/source/images/payment/diners_protectbuy.svg`" alt="diners">
                        </li>
                        <li class="list__item">
                            <img :src="`${myUrl}/source/images/payment/discover_protectbuy.svg`" alt="discover">
                        </li>
                        <!--                        end of 2 new payment methods-->

                        <li class="list__item">
                            <img class="is-light" :src="`${myUrl}/source/images/payment/safekey.svg`" alt="">
                            <img class="is-dark" :src="`${myUrl}/source/images/payment/dark/safekey.svg`" alt="">
                        </li>
                    </ul>
                </div>
            </div>
        </template>

        <template v-else>
            <div class="page__header">
                <button class="button button_round button_back" @click.prevent="goHome">
                    <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" width="14" height="14">
                        <use :xlink:href="`${myUrl}/source/images/icons/icons.svg#back`"/>
                    </svg>
                </button>
                <h3 class="page__heading">Choose your plan</h3>
            </div>
            <div class="page__container">


<!--                <ul class="price price_mobile">-->
<!--                    <li class="price__tab price__item" v-for="tariff in paymentTariffs" :key="tariff.id" @click.prevent="openTariff(tariff)"-->
<!--                        :class="`price__item_${tariff.title.toLowerCase()}`"-->
<!--                    >-->
<!--                        <input type="checkbox" :id="`tariff${tariff.id}`" :checked="tariff.isOpen">-->
<!--                        <label class="price__tab-label" :for="`tariff${tariff.id}`">-->
<!--                            <span class="price__header">-->
<!--                                <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24">-->
<!--                                    <use :xlink:href="`${myUrl}/source/images/icons/icons.svg#${svgIcon[tariff.title]}`"/>-->
<!--                                </svg>-->
<!--                                <span class="price__title">{{ tariff.title }}</span>-->
<!--                            </span>-->
<!--                            <span class="price__description">-->
<!--                                <span class="price__value" v-if="$mq==='mobile'"><span>$</span>{{ Number(tariff.amount) }}</span>-->
<!--                                <span class="price__value" v-else><span>$</span>{{ Number(tariff.amount).toFixed(2) }}</span>-->
<!--                                <span class="price__credit">{{ tariff.credits }} credits</span>-->
<!--                            </span>-->
<!--                        </label>-->
<!--                        <div class="price__tab-content" v-if="tariff.isOpen">-->
<!--                            <price-features-list-->
<!--                               :tariff="tariff"-->
<!--                               :bonuses="getBonus(tariff.amount, tariff.bonus)"-->
<!--                            ></price-features-list>-->
<!--                            <button class="button button_sm button_primary price__button" @click.prevent="select(tariff)">-->
<!--                                <span>Select</span>-->
<!--                            </button>-->
<!--                        </div>-->
<!--                    </li>-->
<!--                </ul>-->

                            <template v-if="paymentTariffs.length===1">
                                <ul class="price price_mobile">
                                    <li class="price__tab price__item price__item_starting" v-for="tariff in paymentTariffs" :key="tariff.id" @click.prevent="openTariff(tariff)">
                                        <input type="checkbox" :id="`tariff${tariff.id}`" :checked="tariff.isOpen">
                                        <label class="price__tab-label" :for="`tariff${tariff.id}`">

                                            <span class="price__header">
                                               <svg xmlns="http://www.w3.org/2000/svg" width="24" height="20"
                                                    viewBox="0 0 24 20" fill="none">
                                                   <path d="M17.625 0C14.7823 0 12.9558 1.87042 12 3.55094C11.0442 1.87042 9.21773 0 6.375 0C2.76736 0 0 2.77832 0 6.46322C0 10.4588 3.46706 13.1798 8.64436 17.2997C11.1795 19.3152 11.3423 19.4684 12 20C12.5367 19.5661 12.8338 19.3047 15.3556 17.2997C20.5329 13.1798 24 10.4588 24 6.46322C24 2.77837 21.2326 0 17.625 0Z" fill="white"/>
                                               </svg>
                                                <span class="price__title">{{ tariff.title }}</span>
                                            </span>
                                            <span class="price__description">
                                                <span class="price__value" v-if="$mq==='mobile'"><span>$</span>{{ Number(tariff.amount/100) }}</span>
                                                <span class="price__value" v-else><span>$</span>{{ Number(tariff.amount/100).toFixed(2) }}</span>
                                                <span class="price__credit">{{(Number(tariff.credits) + Number(getBonus(tariff.amount, tariff.bonus))).toFixed(0)}} credits</span>
                                            </span>
                                        </label>
                                        <div class="price__tab-content" v-if="tariff.isOpen">

                                            <ul class="price__features list">
                                                <li class="list__item">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                        <g clip-path="url(#clip0_982_1349)">
                                                            <path d="M13.8006 2.8703L13.1523 2.22202C12.8852 1.95496 12.4492 1.95496 12.1798 2.22202L5.38202 9.02215L1.82103 5.46116C1.55397 5.19407 1.11796 5.19407 0.848607 5.46116L0.200295 6.10944C-0.0667649 6.37653 -0.0667649 6.81251 0.200295 7.08187L4.89353 11.7751C5.02822 11.9098 5.20168 11.9782 5.37746 11.9782C5.55324 11.9782 5.72899 11.9098 5.86142 11.7751L13.7937 3.84276C14.0676 3.57113 14.0676 3.13742 13.8006 2.8703Z" fill="#00F4F4"/>
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_982_1349">
                                                                <rect width="14" height="14" fill="white"/>
                                                            </clipPath>
                                                        </defs>
                                                    </svg>

                                                    <span>1 Credit costs {{tariff.credit_cost}} cents</span></li>
<!--                                                    <span style="color: #1b1b1b;font-weight: 700;">Free chat, 24H</span></li>-->
                                                <li class="list__item price__features-title">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14"
                                                         height="14" fill="none" class="svg-icon">
                                                        <path fill="currentColor" d="m11.1599 2.11979-5.51998 7.2c-.24.24-.6.48-.96.48s-.72-.12-.96-.48l-2.88-3.72c-.36-.48-.36-1.32.24-1.68.6-.36 1.32-.36 1.68.24l1.92 2.52 4.56-6c.36-.48 1.19998-.6 1.67998-.24.6.36.6 1.08.24 1.68Z"/>
                                                    </svg>
                                                    <span>{{getBonus(tariff.amount, tariff.bonus)}} Bonus credits</span></li>

                                                <template v-if="tariff.addition">
                                                    <li class="list__item" v-for="addition in tariff.addition">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                            <g clip-path="url(#clip0_982_1349)">
                                                                <path d="M13.8006 2.8703L13.1523 2.22202C12.8852 1.95496 12.4492 1.95496 12.1798 2.22202L5.38202 9.02215L1.82103 5.46116C1.55397 5.19407 1.11796 5.19407 0.848607 5.46116L0.200295 6.10944C-0.0667649 6.37653 -0.0667649 6.81251 0.200295 7.08187L4.89353 11.7751C5.02822 11.9098 5.20168 11.9782 5.37746 11.9782C5.55324 11.9782 5.72899 11.9098 5.86142 11.7751L13.7937 3.84276C14.0676 3.57113 14.0676 3.13742 13.8006 2.8703Z" fill="#00F4F4"/>
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_982_1349">
                                                                    <rect width="14" height="14" fill="white"/>
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                        <span >{{addition}}</span>
                                                    </li>
                                                </template>
<!--                                                <li class="list__item">-->
<!--                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">-->
<!--                                                        <g clip-path="url(#clip0_982_1349)">-->
<!--                                                            <path d="M13.8006 2.8703L13.1523 2.22202C12.8852 1.95496 12.4492 1.95496 12.1798 2.22202L5.38202 9.02215L1.82103 5.46116C1.55397 5.19407 1.11796 5.19407 0.848607 5.46116L0.200295 6.10944C-0.0667649 6.37653 -0.0667649 6.81251 0.200295 7.08187L4.89353 11.7751C5.02822 11.9098 5.20168 11.9782 5.37746 11.9782C5.55324 11.9782 5.72899 11.9098 5.86142 11.7751L13.7937 3.84276C14.0676 3.57113 14.0676 3.13742 13.8006 2.8703Z" fill="#00F4F4"/>-->
<!--                                                        </g>-->
<!--                                                        <defs>-->
<!--                                                            <clipPath id="clip0_982_1349">-->
<!--                                                                <rect width="14" height="14" fill="white"/>-->
<!--                                                            </clipPath>-->
<!--                                                        </defs>-->
<!--                                                    </svg>-->
<!--                                                    <span >Standard online assistant</span></li>-->
<!--                                                <li class="list__item">-->
<!--                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">-->
<!--                                                        <g clip-path="url(#clip0_982_1349)">-->
<!--                                                            <path d="M13.8006 2.8703L13.1523 2.22202C12.8852 1.95496 12.4492 1.95496 12.1798 2.22202L5.38202 9.02215L1.82103 5.46116C1.55397 5.19407 1.11796 5.19407 0.848607 5.46116L0.200295 6.10944C-0.0667649 6.37653 -0.0667649 6.81251 0.200295 7.08187L4.89353 11.7751C5.02822 11.9098 5.20168 11.9782 5.37746 11.9782C5.55324 11.9782 5.72899 11.9098 5.86142 11.7751L13.7937 3.84276C14.0676 3.57113 14.0676 3.13742 13.8006 2.8703Z" fill="#00F4F4"/>-->
<!--                                                        </g>-->
<!--                                                        <defs>-->
<!--                                                            <clipPath id="clip0_982_1349">-->
<!--                                                                <rect width="14" height="14" fill="white"/>-->
<!--                                                            </clipPath>-->
<!--                                                        </defs>-->
<!--                                                    </svg>-->
<!--                                                    <span>Advertising applies</span></li>-->
                                            </ul>
                                            <button class="button button_sm button_primary price__button" @click.prevent="select(tariff)">
                                                <span>Select</span>
                                            </button>
                                        </div>
                                    </li>
                                </ul>
                            </template>
                            <template v-else>
                                                <ul class="price price_mobile">
                                                    <li class="price__tab price__item" v-for="tariff in paymentTariffs" :key="tariff.id" @click.prevent="openTariff(tariff)"
                                                        :class="`price__item_${tariff.title.toLowerCase()}`"
                                                    >
                                                        <input type="checkbox" :id="`tariff${tariff.id}`" :checked="tariff.isOpen">
                                                        <label class="price__tab-label" :for="`tariff${tariff.id}`">
                                                            <span class="price__header">
                                                                <svg class="icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                                                                    <use :xlink:href="`${myUrl}/source/images/icons/icons.svg#${svgIcon[tariff.title]}`"/>
                                                                </svg>
                                                                <span class="price__title">{{ tariff.title }}</span>
                                                            </span>
                                                            <span class="price__description">
                                                                <span class="price__value" v-if="$mq==='mobile'"><span>$</span>{{ Number(tariff.amount/100) }}</span>
                                                                <span class="price__value" v-else><span>$</span>{{ Number(tariff.amount/100).toFixed(2) }}</span>
                                                                <span class="price__credit">{{(Number(tariff.credits) + Number(getBonus(tariff.amount, tariff.bonus))).toFixed(0)}} credits</span>
                                                            </span>
                                                        </label>

                                                        <div class="price__tab-content" v-if="tariff.isOpen">
                                                            <price-features-list
                                                               :tariff="tariff"
                                                               :bonuses="getBonus(tariff.amount, tariff.bonus)"
                                                            ></price-features-list>
                                                            <button class="button button_sm button_primary price__button" @click.prevent="select(tariff)">
                                                                <span>Select</span>
                                                            </button>
                                                        </div>
                                                    </li>
                                                </ul>
                            </template>
                <section-payment-page :init-show-list="paymentTariffs.length===1"></section-payment-page>


                <div class="page__footer">
                    <div class="page__section">
                        <span><i class="icon icon_secured"></i>Transactions are encrypted and secured</span>
                    </div>
                    <ul class="list list_payment">
                        <li class="list__item">
                            <img :src="`${myUrl}/source/images/payment/pci.svg`" alt="">
                        </li>
                        <li class="list__item">
                            <img :src="`${myUrl}/source/images/payment/visa-secure.svg`" alt="">
                        </li>
                        <li class="list__item">
                            <img class="is-light" :src="`${myUrl}/source/images/payment/master-id.svg`" alt="">
                            <img class="is-dark" :src="`${myUrl}/source/images/payment/dark/master-id.svg`" alt="">
                        </li>
                        <li class="list__item">
                            <img :src="`${myUrl}/source/images/payment/jcb-secure.svg`" alt="">
                        </li>

                        <li class="list__item">
                            <img class="is-light" :src="`${myUrl}/source/images/payment/safekey.svg`" alt="">
                            <img class="is-dark" :src="`${myUrl}/source/images/payment/dark/safekey.svg`" alt="">
                        </li>
                    </ul>
                </div>
            </div>
            <portal selector="#portal-target" v-if="showModalFreeChat">
                <modal-free-chat  v-if="showModalFreeChat"
                              :my-url="myUrl"
                              :promo-name="promoName"
                              :holiday-promotion="holidayPromotion"
                              @close_modal="closeModalFreeChat"
                >
                </modal-free-chat>
            </portal>


        </template>

<!--        //TODO нужно для нескольких видов оплаты-->
<!--        <portal selector="#portal-target" v-if="showModalPayment">-->
<!--            <div class="modal modal_lg modal_payment is-active" id="payment-method" tabindex="-1" role="dialog">-->
<!--                <div class="modal__bg" @click.prevent="closeModalPayment"></div>-->
<!--                <div class="modal__container">-->
<!--                    <span class="modal__close"  role="button" aria-label="Close" @click.prevent="closeModalPayment">-->
<!--                        <i class="icon icon_close"></i>-->
<!--                    </span>-->
<!--                    <div class="modal__header">-->
<!--                        <h2 class="modal__title">Payment method</h2>-->
<!--                    </div>-->
<!--                    <div class="modal__body">-->
<!--                        <div class="form">-->

<!--                            <div class="form__row">-->
<!--&lt;!&ndash;                                <label>&ndash;&gt;-->
<!--&lt;!&ndash;                                    <input name="payment" type="radio" class="ui-radio ui-radio_payment" value="other" v-model="showOtherMethods">&ndash;&gt;-->
<!--&lt;!&ndash;                                    <span class="label">&ndash;&gt;-->
<!--&lt;!&ndash;                                        <span class="label__title" >Credit Card</span>&ndash;&gt;-->
<!--&lt;!&ndash;                                         <span class="label__subtitle">&ndash;&gt;-->
<!--&lt;!&ndash;                                            <img :src="`${myUrl}/source/images/payment/ccbill.svg`" alt="payment ccbill" @click.prevent="">&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                            <img class="is-light" :src="`${myUrl}/source/images/payment/crypto.svg`" alt="payment crypto">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                            <img class="is-dark" :src="`${myUrl}/source/images/payment/dark/crypto.svg`" alt="payment crypto">&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                        </span>&ndash;&gt;-->
<!--&lt;!&ndash;                                        &ndash;&gt;-->
<!--&lt;!&ndash;                                    </span>&ndash;&gt;-->
<!--&lt;!&ndash;                                </label>&ndash;&gt;-->

<!--                                <form @click.prevent="submitFormCC"  ref="formCC" method="GET"-->
<!--                                      :action="ccBillTariff.urlForm ? ccBillTariff.urlForm : 'https://api.ccbill.com/wap-frontflex/flexforms/affa2ab5-075c-41a4-90ef-87137d6f6d3f'">-->

<!--                                    <input name="payment" type="radio" class="ui-radio ui-radio_payment" value="other" v-model="showOtherMethods">-->
<!--                                    <span class="label">-->
<!--                                        <span class="label__title" >Credit Card</span>-->
<!--                                        <span class="label__subtitle">-->
<!--&lt;!&ndash;                                            <img :src="`${myUrl}/source/images/payment/ccbill.svg`" alt="payment ccbill" >&ndash;&gt;-->
<!--                                            <img :src="`${myUrl}/source/images/payment/visa-card.svg`" alt="">-->
<!--                                            <img class="is-light" :src="`${myUrl}/source/images/payment/master-card.svg`" alt="">-->
<!--                                            <img class="is-dark"  :src="`${myUrl}/source/images/payment/dark/master-card.svg`" alt="">-->
<!--                                            <img :src="`${myUrl}/source/images/payment/otp.svg`" alt="">-->
<!--                                            <img :src="`${myUrl}/source/images/payment/dis.svg`" alt="">-->
<!--                                            <img :src="`${myUrl}/source/images/payment/jcb-card.svg`" alt="">-->

<!--                                        </span>-->
<!--                                    </span>-->

<!--                                    <input type=hidden name=subscriptionTypeId :value="ccBillTariff.subscriptionTypeId" >-->
<!--                                    <input type=hidden name=currencyCode :value="ccBillTariff.currencyCode" >-->
<!--                                    <input type=hidden name=clientSubacc :value="ccBillTariff.clientSubacc" >-->
<!--                                    <input type=hidden name=user_id :value="currentUser.id">-->

<!--                                </form>-->

<!--                            </div>-->

<!--&lt;!&ndash;                            <div class="form__section" >&ndash;&gt;-->
<!--&lt;!&ndash;                                <form method="GET" action="https://api.ccbill.com/wap-frontflex/flexforms/15dd1c39-627d-4003-9a27-6e04ee464674">&ndash;&gt;-->
<!--&lt;!&ndash;                                    <input type=hidden name=subscriptionTypeId :value="ccBillTariff.subscriptionTypeId" >&ndash;&gt;-->
<!--&lt;!&ndash;                                    <input type=hidden name=currencyCode :value="ccBillTariff.currencyCode" >&ndash;&gt;-->
<!--&lt;!&ndash;                                    <input type=hidden name=clientSubacc :value="ccBillTariff.clientSubacc" >&ndash;&gt;-->
<!--&lt;!&ndash;                                    <input type=hidden name=user_id :value="currentUser.id">&ndash;&gt;-->
<!--&lt;!&ndash;                                    &lt;!&ndash;                                        <button type=submit class="btn btn_price"><i class="icon-apply"></i>Apply</button>&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                    <button class="button button_payment" ref="buttonPaymentCCBill">&ndash;&gt;-->
<!--&lt;!&ndash;                                        <img :src="`${myUrl}/source/images/payment/ccbill.svg`" alt="">&ndash;&gt;-->
<!--&lt;!&ndash;                                    </button>&ndash;&gt;-->
<!--&lt;!&ndash;                                </form>&ndash;&gt;-->
<!--&lt;!&ndash;&lt;!&ndash;                                @click.prevent="goCryptoPayment"&ndash;&gt;&ndash;&gt;-->
<!--&lt;!&ndash;                                <button class="button button_payment" @click.prevent="goCryptoPayment" :disabled="disabledButton">&ndash;&gt;-->
<!--&lt;!&ndash;                                    <img class="is-light" :src="`${myUrl}/source/images/payment/crypto.svg`" alt="">&ndash;&gt;-->
<!--&lt;!&ndash;                                    <img class="is-dark" :src="`${myUrl}/source/images/payment/dark//crypto.svg`" alt="">&ndash;&gt;-->
<!--&lt;!&ndash;                                </button>&ndash;&gt;-->
<!--&lt;!&ndash;                            </div>&ndash;&gt;-->

<!--                            <div class="form__row" >-->
<!--                                <label @click.prevent ="goToPaymentCG('card')">-->
<!--                                    <input type="radio" class="ui-radio ui-radio_payment" name="payment" value="cg" v-model="showOtherMethods" :disabled="disabledButton">-->
<!--                                    <span class="label">-->
<!--                                        <span class="label__title">Other payment methods</span>-->
<!--                                        <span class="label__subtitle">-->
<!--                                            <img :src="`${myUrl}/source/images/payment/comerceGate.svg`" alt="payment comerceGate" >-->
<!--&lt;!&ndash;                                            <img :src="`${myUrl}/source/images/payment/crypto.svg`" alt="payment crypto" >&ndash;&gt;-->
<!--                                        </span>-->
<!--                                    </span>-->
<!--                                </label>-->
<!--                            </div>-->
<!--                            <button class="button button_payment" @click.prevent="goMagnetiqPayment" :disabled="disabledButton">-->
<!--                                Magnetiq Payment-->
<!--                            </button>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="modal__footer">-->
<!--                        <span class="text"><i class="icon icon_secured"></i>Transactions are encrypted and secured</span>-->
<!--                        <ul class="list list_payment">-->
<!--                            <li class="list__item">-->
<!--                                <img :src="`${myUrl}/source/images/payment/pci.svg`" alt="">-->
<!--                            </li>-->
<!--                            <li class="list__item">-->
<!--                                <img :src="`${myUrl}/source/images/payment/visa-secure.svg`" alt="">-->
<!--                            </li>-->
<!--                            <li class="list__item">-->
<!--                                <img class="is-light" :src="`${myUrl}/source/images/payment/master-id.svg`" alt="">-->
<!--                                <img class="is-dark" :src="`${myUrl}/source/images/payment/dark/master-id.svg`" alt="">-->
<!--                            </li>-->
<!--                            <li class="list__item">-->
<!--                                <img :src="`${myUrl}/source/images/payment/jcb-secure.svg`" alt="">-->
<!--                            </li>-->
<!--                        </ul>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </portal>-->

        <modal-payment-crypto v-if="showModalCrypto && cryptoPaymentPage"
                              :my-url="myUrl"
                              :crypto-payment-page="cryptoPaymentPage"
                              @close_modal="closeModalCrypto"
                              @go_to_payment="goBack"
        ></modal-payment-crypto>
        <div v-show="false">
            <form :action="actionMagnqtiq" method="post" v-if="actionMagnqtiq" id="submitMaqnetiq">

                <input v-for="(value , key) in fieldsMagnqtiq" type="hidden" :name="key" :value="value" />

                <input type="submit" name="SUBMIT" value="SUBMIT" />
                <br>Submitting request to: <b>{{actionMagnqtiq}}</b>
            </form>

            <form method="GET" id="paymentCCBillForm"
                  :action="ccBillTariff.urlForm ? ccBillTariff.urlForm: 'https://api.ccbill.com/wap-frontflex/flexforms/affa2ab5-075c-41a4-90ef-87137d6f6d3f'" v-if="ccBillTariff">
                <input type=hidden name=subscriptionTypeId :value="ccBillTariff.subscriptionTypeId">
                <input type=hidden name=currencyCode :value="ccBillTariff.currencyCode">
                <input type=hidden name=clientSubacc :value="ccBillTariff.clientSubacc">
                <input type=hidden name=user_id :value="currentUser.id">
                <!--                                        <button type=submit class="btn btn_price"><i class="icon-apply"></i>Apply</button>-->
                <button class="button button_payment" ref="buttonPaymentCCBill" @click="goPaymentCCBill">
                    <img :src="`${myUrl}/source/images/payment/ccbill.svg`" alt="">
                </button>
            </form>

        </div>

    </div>
</template>

<script>
import BaseLogo from "../newModules/BaseLogo.vue";
import PriceFeaturesList from "../newModules/modulePaymentPage/PriceFeaturesList.vue";
import ListWithCredits from "../newModules/modulePaymentPage/ListWithCredits.vue";
import SectionPaymentPage from "../newModules/modulePaymentPage/SectionPaymentPage.vue";
import CountdownPromo from "../newModules/CountdownPromo.vue";
import ModalPaymentCrypto from "../newModules/modals/ModalPaymentCrypto.vue";
import ModalFreeChat from "../newModules/modals/ModalFreeChat.vue";
import ModalPromo from "../newModules/modals/ModalPromo.vue";

export default {
    name: "PaymentPage",
    components: {
        ModalPromo,
        ModalFreeChat,
        ModalPaymentCrypto, CountdownPromo, SectionPaymentPage, ListWithCredits, PriceFeaturesList, BaseLogo},
    props: ['myUrl', 'show', 'currentUser', 'holidayPromotionName'],
    data() {
        return {
            paymentPreference: '',
            disabledButton: false,
            paymentTariffs: [],
            holidayPromotion: null,
            showModalPayment: false,
            showModalCrypto: false,
            selectedAmount: null,
            ccBillTariffs: [],
            ccBillTariff: null,
            //showOtherMethods: '',
            inaccuracy: 1.1,
            cryptoPaymentPage: '',
            actionMagnqtiq: '',
            fieldsMagnqtiq: [],
            svgIcon: {
                Base: 'heart-white',
                Expert: 'star',
                Pro: 'cristal',
                Top: 'crown',
            },
            showModalFreeChat:false,
            promoName:''
        }
    },
    created() {
        this.initPaymentTarrifs();
    },
    mounted() {
        // this.$bus.$emit('open_modal');
        this.$bus.$emit('close_nav_menu');
        this.$bus.$emit('close_modal');

    },
    beforeDestroy() {
        this.$bus.$emit('close_modal');
        // this.$bus.$emit('close_modal');
        // setTimeout(()=>{
        //     this.$bus.$emit('close_nav_menu');
        // },10)
    },
    methods: {
        checkIfPromo(){
            if(this.holidayPromotionName === 'freechat' && this.$mq === 'mobile' && this.paymentTariffs.length === 1){
                setTimeout(()=>{
                    //console.log('OPEN MODAL FREE CHAT')
                    this.promoName = 'freechat'
                    this.showModalFreeChat=true
                }, 0)
            }
        },
        closeModalFreeChat(){
            this.promoName = ''
            this.showModalFreeChat = false;
        },
        closeModalCrypto() {
            this.showModalCrypto = false;
        },
        initPaymentTarrifs() {
            axios.get('/get-payment-tariffs')
                .then(response => {
                    const data = response.data;
                    console.log(data)
                    if(data.status === 'unauthorized'){
                        window.location.href = '/'
                    }else if(data.status === 'ok'){
                        console.log('get-payment-tariffs success!');
                        this.paymentTariffs = data.paymentTariffs;
                        if(this.paymentTariffs){
                            this.paymentTariffs.forEach(tariff => {
                                this.$set(tariff, 'isOpen', false);
                            })
                        }
                        this.holidayPromotion = data.holidayPromotion;
                        this.ccBillTariffs = data.ccBillTariffs;
                        this.paymentPreference = data.payment_preference
                        // this.sendGoogleEcommerce()
                    }
                })
                .catch(e => {
                    this.errorHandler(e);
                    console.log(e);
                })
                .finally(()=>this.checkIfPromo())
        },
        stopPromo() {
            console.log('stopPromo');
            this.initPaymentTarrifs();
        },
        goPaymentCCBill(){
            console.log('goPaymentCCBill');
            axios.get('/click-payment-ccbill')
                .then(response => {
                    this.disabledButton = false;
                    console.log(response.data)
                })
                .catch(e => {
                    this.disabledButton = false;
                    this.errorHandler(e);
                    console.log(e);
                })
        },
        submitFormCC(){
            console.log('submitFormCC');
            axios.get('/click-payment-ccbill')
                .then(response => {
                    this.disabledButton = false;
                })
                .catch(e => {
                    this.disabledButton = false;
                    this.errorHandler(e);
                    console.log(e);
                })
            this.showOtherMethods = 'other'
            const form = this.$refs.formCC;
            if (form) {
                form.submit();
            }
        },
        goMagnetiqPayment(){
            console.log('goMagnetiqPayment')
            axios.post('/click-magnetiq-payment',{
                user_id: this.currentUser.id,
                amount: this.selectedAmount*100,
                currency: 'EUR'
            })
                .then(response => {
                    this.disabledButton = false;
                    const data = response.data
                    console.log(data)
                    this.fieldsMagnqtiq = data.fields
                    this.actionMagnqtiq = data.action
                    this.$nextTick(()=>{
                        const submitEl = document.getElementById('submitMaqnetiq')
                        console.log(submitEl)
                        if(submitEl) submitEl.submit()
                    })

                })
                .catch(e => {
                    this.disabledButton = false;
                    this.errorHandler(e);
                    console.log(e);
                })
        },
        // goCryptoPayment() {
        //     return;
        //     console.log('goCryptoPayment');
        //     if (this.disabledButton) return;
        //     this.disabledButton = true;
        //     axios.post('/get-crypto-coinsbuy-payment', {
        //         amount: this.selectedAmount,
        //         inaccuracy: this.inaccuracy
        //     })
        //         .then(response => {
        //             this.disabledButton = false;
        //             if (response.data.status === 'ok') {
        //                 console.log(response.data);
        //                 this.showModalCrypto = true;
        //                 this.showModalPayment = false;
        //                 this.cryptoPaymentPage = response.data.payment_page;
        //                 // window.location.href = response.data.payment_page
        //             }
        //         })
        //         .catch(e => {
        //             this.disabledButton = false;
        //             this.errorHandler(e);
        //             console.log(e);
        //         })
        // },
        goHome() {
            console.log('goHome');
            if (this.$route.name !== 'gallery') {
                this.$router.push({name: 'gallery'});
            }
        },
        openTariff(tariff) {
            console.log('open tariff:', tariff.isOpen)
            tariff.isOpen = !tariff.isOpen
        },
        goBack() {
            this.$router.back();
        },
        openTariffsModal() {
            this.$bus.$emit('open_modal_price');
        },
        sendGoogleEcommerce() {
            // try {
            //     dataLayer.push({ ecommerce: null });      // Clear the previous ecommerce object.
            //     dataLayer.push({
            //         'ecommerce': {
            //             'currencyCode': 'USD',            // Local currency is optional.
            //             'impressions': this.paymentTariffs.map(tariff => ({
            //                     'name': tariff.title,       // Name or ID is required.
            //                     'id': Number(tariff.id).toFixed(0),
            //                     'price': Number(tariff.amount).toFixed(2),
            //                     'brand': 'lifeforlove',
            //                     'variant': 'credirs',
            //                     'list': 'Search Results',
            //                     'position': 1
            //             }))
            //         }
            //     });
            //
            //
            //
            // }catch (e) {
            //     console.log(e)
            // }
        },
        clickSelect(tariff) {
            try {
                console.log('clickSelect');
                dataLayer.push({ecommerce: null});  // Clear the previous ecommerce object.
                dataLayer.push({
                    event: "add_to_cart",
                    ecommerce: {
                        items: [{
                            item_name: tariff.title, // Название товара
                            item_id: Number(tariff.id).toFixed(0),
                            price: Number(tariff.amount/100).toFixed(2),
                            item_brand: "lifeforlove",
                            item_variant: "credits", // количество кредитов
                            quantity: 1
                        }]
                    }
                });
            } catch (e) {
                console.log(e)
            }

        },
        errorHandler(e) {
            this.$emit('errorhandler', e);
        },
        getBonus(amount, bonus) {
            if (this.holidayPromotion && this.holidayPromotion.bonuses) {
                return this.holidayPromotion.bonuses[amount] ? this.holidayPromotion.bonuses[amount] : 0
            }
            return bonus;
        },
        close() {
            this.showModalPayment = false;
            this.$emit('cancel_price');
            // document.body.classList.remove("modal-open")
        },
        // open() {
        //     this.$emit('open_price');
        //     // document.body.classList.add("modal-open")
        // },
        select(tariff) {
            if(this.disabledButton || !this.currentUser ) return;
            this.disabledButton = true;
            //this.showModalPayment = true;
            this.inaccuracy = tariff.inaccuracy;
            //this.$bus.$emit('open_modal');
            console.log(tariff);
            this.selectedAmount = tariff.amount;
            this.ccBillTariff = this.ccBillTariffs.find(ccBillTariff => {
                return +ccBillTariff.amount === +tariff.amount
            });
            //TODO после настройки google тег-менеджер
            this.clickSelect(tariff);

            //const dateNewUser = new Date('2024-08-27T00:00:00')

            if(this.paymentPreference === 'ccbill'){
                //this.goPaymentCCBill()
                this.$nextTick(()=>{
                    try {
                        const paymentCCBillForm = document.getElementById('paymentCCBillForm')
                        if(paymentCCBillForm) paymentCCBillForm.submit()
                        else console.error('!paymentCCBillForm')
                    }catch (e) {
                        console.error(e)
                    }
                })

                // const button = this.$refs.buttonPaymentCCBill;
                // if(button) button.click();
                // else console.error('!buttonPaymentCCBill')
            }else if(this.paymentPreference === 'magnetiq'){
                this.goMagnetiqPayment()
            //}else if( this.currentUser.payment_preference === 'cg' || !this.currentUser.payment_preference){
            }else {
                this.goToPaymentCG()
            }

            //this.goToPaymentCG('card')
        },
        closeModalPayment() {
            this.showModalPayment = false
            this.$bus.$emit('close_modal');
            // this.$emit('handle_resize')
        },
        // select(tariff){
        //     this.$emit('close_price');
        //     console.log(this.$route.name)
        //     if (this.$route.name !== 'payment') {
        //         this.$router.push({
        //             name: 'payment',
        //             params: {
        //                 amount: tariff.amount,
        //                 bonus:this.getBonus(tariff.amount,tariff.bonus),
        //                 currentUser: this.currentUser,
        //                 tariff: tariff,
        //             }
        //         });
        //     }
        //     else {
        //         console.log(this.$route.name)
        //         const param = {
        //             amount: tariff.amount,
        //             tariff: tariff,
        //             bonus:this.getBonus(tariff.amount,tariff.bonus),
        //         }
        //         this.$bus.$emit('update-payment-tariff',param )
        //     }
        // },
        goToPaymentCG(paymentMethod) {
            console.log('goToFormCG');
            // if (this.disabledButton) return;
            // this.showOtherMethods = 'cg';
            //
            // if (this.currentUser && this.currentUser.payment_preference === 'ccbill') {
            //     this.submitFormCC()
            //     return;
            // }

            axios.get(`/get-url-payment-form-cg/payment/${this.selectedAmount}/card`)
                .then(response => {
                    console.log(response.data);
                    this.disabledButton = false;
                    if (response.data.status === 'pending') {
                        console.log('status pending');
                        this.$router.push({path: `/payment-cg-status/${response.data.amount}/${response.data.id}`});
                        this.close();
                    } else if (response.data.status === 'ok' && response.data.bodyForm) {
                        if (response.data.bodyForm && response.data.bodyForm.forwardUrl) window.location.href = response.data.bodyForm.forwardUrl;
                        else alert('For this domain, the payment gateway is not configured, please contact technical support for advice')

                    } else alert('For this domain, the payment gateway is not configured, please contact technical support for advice');
                    // this.evenGooglePromo(tariff)
                })
                .catch(e => {
                    console.log(e);
                    this.disabledButton = false;
                    this.errorHandler(e);
                })
        },
        // evenGooglePromo(tariff){
        //     try {
        //         console.log('begin_checkout');
        //         dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        //         dataLayer.push({
        //             event: "begin_checkout",
        //             ecommerce: {
        //                 items: [{
        //                     item_name: tariff.title, // Название товара
        //                     item_id: Number(tariff.id).toFixed(0),
        //                     price: Number(tariff.amount).toFixed(2),
        //                     item_brand: "lifeforlove",
        //                     item_variant: "credits", // количество кредитов
        //                     quantity: 1
        //                 }]
        //             }
        //         });
        //     }catch (e) {
        //         console.log(e)
        //     }
        // },
        // updatepaymentTariffs(data){
        //     console.log('updatepaymentTariffs')
        //     console.log(data)
        //     if(this.selectedAmount !== data.amount){
        //         this.selectedAmount = data.amount;
        //         this.selectedTariff = data.tariff;
        //         this.getPaymentData(data.amount);
        //         this.$router.push({
        //             name: 'payment',
        //             params: {
        //                 amount: data.amount,
        //                 currentUser: this.currentUser,
        //                 tariff: data.tariff,
        //                 bonus:data.bonus,
        //             }
        //         });
        //     }
        // },

        // goToGallery(){
        //     if (this.$route.name !== 'gallery') {
        //         this.$router.push({name: 'gallery'});
        //     }
        // },
        // getPaymentData(amount){
        //     console.log('getPaymentData by tariff '+amount)
        //     axios.get('/get-payment-buttons/'+amount+'/'+this.from_profile_id)
        //         .then(response => {
        //             if(response.data.status === 'ok'){
        //                 console.log('get-payment-buttons success!')
        //                 this.CCBillButtonData = response.data.CCBillButtonData
        //                 // this.CGButtonData = response.data.CGButtonData
        //                 if (+amount === 20) this.selectedPayment = 'CG';
        //                 else this.selectedPayment = 'CCBill';
        //             }
        //         })
        //         .catch(e => {
        //             console.log(e);
        //         })
        // },
    }
}
</script>

<style scoped>

</style>
